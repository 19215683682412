import React, { useState } from "react";
import { message, Upload, Input, Radio, Select } from "antd";
import { UploadProps, Tag } from "antd";
import FlotingLabelContainer from "../../Common/FlotingLabelContainer";
import "./CampaignDetails.scss";

const { Dragger } = Upload;
const CampaignDetails: React.FC = () => {
  const props: UploadProps = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const genderOptions = [
    { label: "All", value: "all" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "other" },
  ];

  return (
    <div className="bg-white h-full flex justify-center items:center mt-10 rounded-lgx px-10">
      <div className="max-w-[46.5rem] w-full h-full bg-white border-primary border p-[32px] rounded-2xl  flex-col justify-start items-start  inline-flex">
        <h3 className="text-stone-900 text-[19px] font-semibold leading-[22.80px] ">
          Campaign details
        </h3>
        <div className="flex-col justify-start mt-5 items-start flex w-full">
          <div className="text-stone-900 text-[13px] font-semibold leading-[16.90px]">
            Add image
          </div>
          <div className="flex justify-center campaignDetails  items-center w-full h-full mt-2 ">
            <Dragger
              {...props}
              className=" lg:min-w-[680px] lg:max-w-[680px] md:min-w-[630px] md:max-w-[640px] sm:min-w-[330px] sm:max-w-[640px] min-h-[170px] max-h-[170px]  bg-white flex flex-col justify-center items-center"
            >
              <div className="w-11 h-11  bg-stone-900 mx-auto my-0 flex justify-center items-center rounded-full">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.1579 7.48612H5.3804C3.68457 7.48612 2.30957 8.86112 2.30957 10.557L2.30957 14.6195C2.30957 16.3145 3.68457 17.6895 5.3804 17.6895H14.6554C16.3512 17.6895 17.7262 16.3145 17.7262 14.6195V10.5486C17.7262 8.85779 16.3554 7.48612 14.6646 7.48612H13.8787"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.0182 1.82521V11.8594"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.58887 4.26562L10.018 1.82562L12.448 4.26562"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <p className="text-[#666] mt-3 text-sm not-italic font-normal leading-[140%]">
                Drag and drop or browse to choose a file
              </p>
            </Dragger>
          </div>
          <div className="justify-center mt-3 flex items-center">
            <div className=" mr-1">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 14.5C4.96281 14.5 2.5 12.0378 2.5 9C2.5 5.96281 4.96281 3.5 8 3.5C11.0378 3.5 13.5 5.96281 13.5 9C13.5 12.0378 11.0378 14.5 8 14.5Z"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.00324 11.2578L8.00324 8.6303"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.00328 6.74372L7.99734 6.74372"
                  stroke="#1F1F1F"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <p className="text-stone-500 text-xs font-medium leading-none ">
              PNG or JPG are allowed
            </p>
          </div>
          <FlotingLabelContainer />
          <div className="gap-5 flex flex-col mt-5">
            <p className=" text-[13px] not-italic font-semibold leading-[130%] ">
              Prefered gender
            </p>
            <Radio.Group>
              {genderOptions.map((option) => (
                <Radio
                  key={option.value}
                  value={option.value}
                  className="custom-radio"
                >
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignDetails;
