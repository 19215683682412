import React, { useState } from "react";
import { Checkbox, Col, Input, Row } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { title } from "process";
import { CloseIconSm, SearchIcon } from "../../../Icons";
import "./CheckboxGroup.scss";

interface Option {
  label: string;
  value: string;
  disabled?: boolean;
}

interface ICheckboxGroupProps {
  title?: string;
  options: Option[];
  span?: number;
  gutter?: [number, number];
  bodyClassName?: string;
  maxItems?: number;
}

const CheckboxGroup: React.FC<ICheckboxGroupProps> = ({
  title,
  options,
  span = 8,
  gutter = [16, 16],
  bodyClassName,
  maxItems = 5,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [displayCount, setDisplayCount] = useState<number>(maxItems + 1);
  const onChange = (checkedValues: CheckboxValueType[]) => {
    // console.log('checked = ', checkedValues);
  };

  const visibleItems = options.slice(0, displayCount);

  const handleShowMore = () => {
    setDisplayCount(options.length);
  };

  const handleShowLess = () => {
    setDisplayCount(maxItems + 1);
  };

  const handleFocus = () => {
    setIsExpanded(true);
  };

  const handleBlur = () => {
    setIsExpanded(false);
  };

  return (
    <div
      className={`pb-5 border-b-gray_30 border-b border-solid ${bodyClassName}`}
    >
      <div className="flex mb-2 items-center justify-between">
        {title && (
          <h4 className="text-primary text-[13px] font-semibold leading-[130%]">
            {title}
          </h4>
        )}
        <div
          className={`${
            isExpanded ? "w-[231px]" : "w-8"
          } transition-[width] duration-[0.3s] ease-[ease-in-out]`}
        >
          <Input
            className={`expand-search-bar p-2 h-8 ${
              isExpanded ? "expanded" : ""
            }`}
            type="text"
            placeholder="Search"
            prefix={<SearchIcon width={16} height={16} />}
            suffix={
              isExpanded && (
                <div className="cursor-pointer" onClick={handleBlur}>
                  <CloseIconSm />
                </div>
              )
            }
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
        <Row gutter={gutter}>
          {visibleItems.map((option) => {
            return (
              <Col span={span}>
                <Checkbox
                  rootClassName="checkbox-custom primary-checkbox"
                  value={option.value}
                >
                  <span className="text-primary text-sm font-normal leading-[140%] select-none">
                    {option.label}
                  </span>
                </Checkbox>
              </Col>
            );
          })}
        </Row>
      </Checkbox.Group>
      {options.length > displayCount && (
        <button
          onClick={handleShowMore}
          className="text-primary text-[13px] font-semibold leading-[130%] underline mt-5 hover:text-link_hover"
        >
          {"+" + (options.length - displayCount)} more
        </button>
      )}
      {options.length === displayCount && (
        <button
          onClick={handleShowLess}
          className="text-primary text-[13px] font-semibold leading-[130%] underline mt-5 hover:text-link_hover"
        >
          show less
        </button>
      )}
    </div>
  );
};

export default CheckboxGroup;
