import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/Login";
import SignUpPage from "./pages/SignUp";
import Dashboard from "./pages/Dashboard";
import Campaign from "./pages/CreateCampaign";

import Onboarding from "./pages/Onboarding";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/onboard" element={<Onboarding />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/create-campaign" element={<Campaign />} />
        <Route path="/signup" element={<SignUpPage />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
