import React from "react";
import LoginArtBoat from "../../assets/png/loginArt.png";
import LoginArt from "../../assets/png/loginArt2.png";

const HeroSection = () => {
  return (
    <div className="w-[618px] lg:flex flex-col justify-between bg-gradient-yellow bg-gradient-black">
      <div className="p-2 p-16 leading-[48px] text-primary mt-[88px] ml-[72px]">
        <h1 className="w-[350px] ml-4 mt-4 h-36 text-[40px] font-extrabold">
          Fuel your brand's reach with Influencers
        </h1>
      </div>
      <img
        src={LoginArtBoat}
        alt="Logo"
        className="w-[561.03125px] h-[554.018310546875px] custom:w-[461.03125px] custom:h-[454.018310546875px] absolute right-[198px] bottom-0 custom:right-[260px]"
      />
      <img
        src={LoginArt}
        alt="Logo"
        className="w-[79.51124572753906px] h-[238.3936767578125px] absolute right-0 bottom-[32px]"
      />
    </div>
  );
};

export default HeroSection;
