import React from "react";
import lock from "../../assets/svg/lock.svg";

interface SubscriptionLockProps {
  imgSrc: string;
  heading: string;
  containerClass: string;
  opacity: number;
}

const SubscriptionLock: React.FC<SubscriptionLockProps> = ({
  imgSrc,
  heading,
  containerClass,
  opacity,
}) => {
  return (
    <div className={`${containerClass} overflow-hidden`}>
      <div className="text-primary">
        <div
          className="absolute inset-0  bg-cover rounded-[20px] border-none outline-none  "
          style={{
            backgroundImage: `url(${imgSrc})`,
            opacity: opacity,
          }}
        ></div>
      </div>
      {/* Overlay */}
      <div className="px-6 pt-6 bg-transparent absolute ">
        <h6 className=" z-50 opacity-100 text-[19px] not-italic font-semibold leading-[120%] tracking-[-1px]">
          {heading}
        </h6>
      </div>
      <div className="absolute inset-0 flex flex-col items-center justify-center z-10">
        <div className=" rounded-[16px] flex flex-col items-center py-6">
          <div className="w-10 cursor-pointer h-10 p-2 bg-yellow-400 rounded-[10px] inline-flex justify-center items-center">
            <img src={lock} className="w-6 h-6" alt="Unlock Icon" />
          </div>
          <div className="text-stone-900 text-xs font-semibold mt-2 ">
            Subscribe to unlock
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionLock;
