import React, { CSSProperties } from "react";
import { Slider } from "antd";
import "./CustomSlider.scss";
import CustomSelect from "../CustomSelect/CustomSelect";

interface ICustomSliderProps {
  title?: string;
  defaultValue?: [number, number];
  handleStyle?: CSSProperties[];
  trackStyle?: CSSProperties[];
  sliderClass?: string;
  bodyClassName?: string;
  range: any;
  min?: number;
  max?: number;
  tooltip?: SliderTooltipProps;
  setSliderValue?: React.Dispatch<React.SetStateAction<any>>;
  sliderValue?: any;
}

type TooltipPlacement =
  | "top"
  | "left"
  | "right"
  | "bottom"
  | "topLeft"
  | "topRight"
  | "bottomLeft"
  | "bottomRight"
  | "leftTop"
  | "leftBottom"
  | "rightTop"
  | "rightBottom";

interface SliderTooltipProps {
  prefixCls?: string;
  open?: boolean;
  placement?: TooltipPlacement;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  formatter?: null | Formatter;
  autoAdjustOverflow?: boolean;
}

type Formatter = (value?: number) => React.ReactNode;

const CustomSlider: React.FC<ICustomSliderProps> = ({
  title,
  sliderClass,
  bodyClassName,
  range = false,
  trackStyle,
  handleStyle,
  defaultValue = [20, 50],
  min = 0,
  max = 100,
  tooltip,
  setSliderValue,
  sliderValue,
}) => {
  const handleSliderChange = (newValue: any) => {
    if (setSliderValue) {
      setSliderValue(newValue);
    }
  };

  return (
    <div
      className={`pb-5 border-b-gray_30 border-b border-solid ${bodyClassName}`}
    >
      {title && (
        <h4 className="text-primary text-[13px] font-semibold leading-[130%]">
          {title}
        </h4>
      )}
      <Slider
        className={`custom-slider ${sliderClass}`}
        trackStyle={trackStyle}
        handleStyle={handleStyle}
        defaultValue={defaultValue}
        range={range}
        min={min}
        max={max}
        tooltip={tooltip}
        onChange={handleSliderChange}
        value={sliderValue}
      />
      <div className="flex items-center justify-evenly gap-4">
        <CustomSelect
          style={{ width: "100%" }}
          placeholder={
            <span className="text-primary text-sm font-normal leading-[140%]">
              Min
            </span>
          }
          options={[
            { value: "0", label: "0" },
            { value: "10", label: "10" },
          ]}
        />
        <span className="text-link_hover text-sm font-semibold leading-[140%]">
          to
        </span>
        <CustomSelect
          style={{ width: "100%" }}
          placeholder={
            <span className="text-primary text-sm font-normal leading-[140%]">
              Max
            </span>
          }
          options={[
            { value: "100", label: "100" },
            { value: "1000", label: "1000" },
          ]}
        />
      </div>
    </div>
  );
};

export default CustomSlider;
