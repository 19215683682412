import React, { useRef } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import cardAvatar from '../../assets/png/reels.png';
import owlNext from '../../assets/svg/owlNext.svg';
import owlPrev from '../../assets/svg/owlPrev.svg';
import { Link, useNavigate } from 'react-router-dom';

interface ContentSuggestionItem {
	title: string;
	description: string;
	image: string;
}

interface ContentSuggestionProps {
	showSub: boolean;
	setShowSub: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContentSuggestion: React.FC<ContentSuggestionProps> = ({ showSub, setShowSub }) => {
	const owlRef = useRef<any>(null);

	const contentSuggestionData: ContentSuggestionItem[] = [
		{
			title: 'Instagram Reels Suggestion',
			description:
				'This is an intricately designed suggestion to promote your product using Instagram Reels. Showcase your creativity and connect with your audience through short video content.',
			image: cardAvatar,
		},
		{
			title: 'Facebook Stories Campaign',
			description:
				"Engage your audience with captivating stories on Facebook. Highlight your brand's unique features and connect with your customers in a more personal way.",
			image: cardAvatar,
		},
		{
			title: 'Facebook Stories Campaign',
			description:
				"Engage your audience with captivating stories on Facebook. Highlight your brand's unique features and connect with your customers in a more personal way.",
			image: cardAvatar,
		},
		{
			title: 'Instagram Reels Suggestion',
			description:
				'This is an intricately designed suggestion to promote your product using Instagram Reels. Showcase your creativity and connect with your audience through short video content.',
			image: cardAvatar,
		},
		{
			title: 'Facebook Stories Campaign',
			description:
				"Engage your audience with captivating stories on Facebook. Highlight your brand's unique features and connect with your customers in a more personal way.",
			image: cardAvatar,
		},
		{
			title: 'Facebook Stories Campaign',
			description:
				"Engage your audience with captivating stories on Facebook. Highlight your brand's unique features and connect with your customers in a more personal way.",
			image: cardAvatar,
		},
		{
			title: 'Instagram Reels Suggestion',
			description:
				'This is an intricately designed suggestion to promote your product using Instagram Reels. Showcase your creativity and connect with your audience through short video content.',
			image: cardAvatar,
		},
		{
			title: 'Facebook Stories Campaign',
			description:
				"Engage your audience with captivating stories on Facebook. Highlight your brand's unique features and connect with your customers in a more personal way.",
			image: cardAvatar,
		},
		{
			title: 'Facebook Stories Campaign',
			description:
				"Engage your audience with captivating stories on Facebook. Highlight your brand's unique features and connect with your customers in a more personal way.",
			image: cardAvatar,
		},
	];

	const handlePrevClick = () => {
		if (owlRef.current) {
			owlRef.current.prev();
		}
	};

	const handleNextClick = () => {
		if (owlRef.current) {
			owlRef.current.next();
		}
	};

	return (
		<section className="bg-white  mt-3 relative  rounded-[16px]  px-[24px] py-[25px] border border-solid border-primary">
			<div className="flex w-full items-center justify-between">
				<h6 className=" text-[19px] font-semibold  text-[19px]">Content suggestions</h6>
				<div className="flex">
					<button
						onClick={handlePrevClick}
						type="button"
						className="flex z-30 justify-center items-center  cursor-pointer group focus:outline-none"
						data-carousel-prev
					>
						<span className="inline-flex border-[1.5px] border-solid border-[#A4A4A4] hover:border-transparent justify-center bg-white hover:bg-primary items-center w-[24px] h-[24px] rounded-full group-focus:outline-none">
							<svg
								className="icon-svg transition-fill text-[#A4A4A4] group-hover:text-white"
								width="12"
								height="10"
								viewBox="0 0 12 10"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.5838 4.99827L10.2581 4.99827"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M5.08228 1.51596L1.58366 4.99954L5.08228 8.4837"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
					</button>
					<button
						onClick={handleNextClick}
						type="button"
						className="flex left-0 z-30 justify-center items-center ml-2 cursor-pointer group focus:outline-none"
						data-carousel-next
					>
						<span className="inline-flex justify-center bg-white border-[1.5px] border-solid border-[#A4A4A4] hover:border-transparent hover:bg-primary items-center w-[24px] h-[24px] rounded-full group-focus:outline-none">
							<svg
								className="icon-svg transition-fill text-[#A4A4A4] group-hover:text-white"
								width="12"
								height="10"
								viewBox="0 0 12 10"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10.4162 4.99827L1.74194 4.99827"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M6.91772 1.51596L10.4163 4.99954L6.91772 8.4837"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
					</button>
				</div>
			</div>
			<div className="flex flex-wrap mt-5 w-full ">
				<OwlCarousel
					className="owl-theme"
					startPosition={0}
					dots={false}
					ref={owlRef}
					margin={12}
					responsive={{
						0: { items: 1 },
						600: { items: 2 },
						1200: { items: 3 },
					}}
				>
					{contentSuggestionData?.map((contentSuggestion, index) => (
						<div key={index} className=" p-2 border border-solid border-[#EFEFEF] rounded-[12px]">
							<div className=" rounded-lg overflow-hidden">
								<span className=" bg-[#EFEFEF] rounded-lg h-[110px] flex  justify-center items-end ">
									<img src={contentSuggestion.image} alt="image" className="custom-carousel-image w-full" />
								</span>

								<div className="p-[12px] text-left w-full ">
									<h4 className=" text-base font-semibold leading-[19px] mt-2">{contentSuggestion.title}</h4>
									<p className="text-[13px] mt-3 not-italic font-medium text-primary line-clamp-2">
										{contentSuggestion.description}
									</p>
									<Link to="/create-campaign">
										<button
											className=" mt-[24px] w-full
                    h-[41px]
                     inline-block
                     border-none
                     px-2.5 py-3 bg-primary
                     rounded-[6px]
                     hover:bg-black_button_hover hover:text-primary
                     text-[13px]  font-[600] leading-[130%]
                     text-white
                     "
										>
											Create campaign
										</button>
									</Link>
								</div>
							</div>
						</div>
					))}
				</OwlCarousel>
			</div>
		</section>
	);
};

export default ContentSuggestion;
