import React, { useState } from "react";
import { Checkbox, InputNumber } from "antd";
import "./DeliverableItem.scss";
import { DownArrow, UpArrow } from "../../../Icons";
import "../../../styles/InputNumber.css";

interface DeliverableItemProps {
  icon: string;
  label: string;
  onAddToSelected: (selectedItem: any) => void;
}

const DeliverableItem: React.FC<DeliverableItemProps> = ({
  icon,
  label,
  onAddToSelected,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [inputValue, setInputValue] = useState(2);

  const onCheckboxChange = (e: any) => {
    const newCheckedValue = e.target.checked;
    setIsChecked(newCheckedValue);

    if (!newCheckedValue) {
      onRemoveFromSelected();
    } else {
      const selectedItem = {
        icon: icon,
        label: label,
        number: inputValue,
        suggestion: false,
      };
      onAddToSelected(selectedItem);
    }
  };

  const onInputNumberChange = (value: number | string | any) => {
    const newValue = Number(value);
    setInputValue(newValue);

    if (isChecked) {
      const selectedItem = {
        icon: icon,
        label: label,
        number: newValue,
        suggestion: false,
      };
      onAddToSelected(selectedItem);
    }
  };

  const onRemoveFromSelected = () => {
    onAddToSelected(null);
  };
  return (
    <div className="border-[#CCCCCC] min-w-[241px] border rounded-[12px] flex flex-col p-5 ">
      <div className="flex items-left">
        <Checkbox
          className="primary-checkbox"
          checked={isChecked}
          onChange={onCheckboxChange}
        />
        <img className="ml-2.5" src={icon} alt={label} />
        <p className="ml-2 text-sm not-italic font-medium leading-[140%]">
          {label}
        </p>
      </div>
      {isChecked && (
        <>
          <div className=" mt-4 w-full flex justify-start pl-[30px] items-start flex-col">
            <InputNumber
              className="custom-input-number always-show-handlers px-4 py-2 text-sm not-italic font-normal leading-[140%]"
              min={1}
              max={10}
              value={inputValue}
              onChange={onInputNumberChange}
              controls={{ upIcon: <UpArrow />, downIcon: <DownArrow /> }}
            />
            <button className=" hover:text-link_hover text-[13px] not-italic  mt-3 font-semibold leading-[130%] underline">
              Add individual price
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default DeliverableItem;
