import React from "react";

const BookDemo: React.FC = () => {
  return (
    <div className="w-[387px] mt-[49px] ">
      <div className="w-[387px] h-full py-[32px] px-[24px] bg-yellow-400 rounded-2xl shadow border border-yellow-600">
        <h3 className=" border w-fit flex justify-center items-center px-2 py-[5px] bg-primary bg-opacity-10 border-primary rounded-[11.5px] border-solid text-primary text-[11px] not-italic font-semibold leading-[100%] ">
          Contact us
        </h3>
        <p className="text-[19px] w-[221px] mt-3 not-italic font-semibold leading-[120%] tracking-[-1px] text-primary">
          Explore social media marketing with us
        </p>
        <button className="text-white  px-4 py-3 rounded-[6px] text-[13px] bg-primary mt-6 font-semibold leading-[16.90px]">
          Book a demo
        </button>
      </div>
    </div>
  );
};

export default BookDemo;
