import React, { useState } from "react";
import SearchIcon from "../../assets/png/DashboardGraph.png";
import campaignAdd from "../../assets/svg/campaignAdd.svg";
import dropdownIcon from "../../assets/svg/dropdownIcon.svg";
import addWhite from "../../assets/svg/plusWhite.svg";
import { Link, useNavigate } from "react-router-dom";

import GroupedBarChart from "./Chart";
import { Select, Space, Tooltip, ConfigProvider } from "antd";
import "./DashboardCampaignCard.scss";

const options = [
  { value: "platform1", label: "Platform 1" },
  { value: "platform2", label: "Platform 2" },
  { value: "platform3", label: "Platform 3" },
];

interface DashboardCampaignCardProps {
  showSub: boolean;
  setShowSub: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardCampaignCard: React.FC<DashboardCampaignCardProps> = ({
  showSub,
  setShowSub,
}) => {
  return (
    <>
      <div
        className={`bg-primary min-w-0 ${
          showSub ? "" : "min-h-[536px]"
        } flex-1 text-white p-[24px] shadow-md p-4 rounded-[16px] relative`}
      >
        {!showSub && (
          <img
            className="h-[210px] w-[192px] absolute top-[-47px] right-[39px]"
            src={SearchIcon}
            alt="Search icon"
          />
        )}
        <h6 className="font-medium text-[19px]">Hello,</h6>
        <h3 className="font-bold text-[28px]">Mason Xavier</h3>
        {showSub ? (
          <div className=" mt-[32px] text-primary w-full  relative">
            <div className="w-[484px] py-10 px-[81px] h-fit bg-yellow-50 rounded-2xl border border-stone-900 flex flex-col justify-start items-center gap-6">
              <div className="flex flex-col text-center justify-start items-center gap-2">
                <div className="text-[19px] not-italic font-semibold leading-[120%] tracking-[-1px]">
                  Start your first campaign today
                </div>
                <div className="text-sm not-italic font-normal leading-[140%]">
                  Start your first campaign by signing up to Big Bang social
                  today...
                </div>
                <button
                  type="submit"
                  className=" hover:bg-black_button_hover hover:text-primary mt-[24px] shadow-[0px_14px_18px_0px_#1F1F1F1F]  flex bg-primary  px-4 py-3 rounded-md items-center justify-center cursor-pointer"
                >
                  <img
                    className="h-[12px] w-[10px] mr-[8px]"
                    src={addWhite}
                    alt="campaignAdd"
                  />
                  <span className="text-[13px] font-semibold text-white">
                    Get started
                  </span>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <Link to="/create-campaign">
              <button
                type="submit"
                className=" hover:bg-black_button_hover hover:text-primary shadow-[0px_14px_18px_0px_#1F1F1F1F] mt-6 flex bg-white w-[166px] h-[41px] px-2 py-3 rounded-md items-center justify-center cursor-pointer"
              >
                <img
                  className="h-[12px] w-[10px] mr-[6px]"
                  src={campaignAdd}
                  alt="campaignAdd"
                />
                <span className="text-[13px] font-semibold text-primary">
                  Create campaign
                </span>
              </button>
            </Link>
            <div className="mt-[49px] chart flex justify-between mb-5 CampaignCard">
              <h6 className="text-[19px] font-semibold">
                Recent launched campaigns
              </h6>
              <div className="select-options-container ">
                <ConfigProvider
                  theme={{
                    token: {
                      colorText: "white",
                      borderRadius: 6,
                      colorBgContainer: "#1F1F1F",
                      colorBorder: "#484848",
                      colorPrimaryHover: "black",
                    },
                  }}
                >
                  <Select
                    getPopupContainer={(trigger) => {
                      return trigger;
                    }}
                    dropdownStyle={{
                      backgroundColor: "#1F1F1F",
                      color: "black",
                      borderRadius: "6px",
                    }}
                    style={{
                      width: 126,
                      backgroundColor: "#1F1F1F",
                      color: "white",
                      borderRadius: "6px",
                    }}
                    defaultValue="All platform"
                    suffixIcon={
                      <img
                        className="w-[16px] h-[16px] flex justify-center items-center"
                        src={dropdownIcon}
                        alt="campaignAdd"
                      />
                    }
                    options={[
                      { value: "platform1", label: "All platform1" },
                      { value: "platform2", label: "All platform2" },
                      { value: "platform3", label: "All platform3" },
                    ]}
                  />
                </ConfigProvider>
              </div>
            </div>

            <GroupedBarChart />
          </>
        )}
      </div>
    </>
  );
};

export default DashboardCampaignCard;
