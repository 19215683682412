import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale, Tooltip, Legend, Title, BarElement } from "chart.js";

Chart.register(CategoryScale, LinearScale, Tooltip, Legend, Title, BarElement);

const GroupedBarChart: React.FC = () => {
  const data1 = [40, 60, 30, 50, 50, 33, 66, 55, 23, 22, 59, 9];

  const chartData = {
    labels: [
      "Black mir...",
      "Item B",
      "Item C",
      "Item B",
      "Item B",
      "Item B",
      "Item B",
      "Item B",
      "Item B",
      "Item B",
      "Item B",
    ],
    
    datasets: [
      {
        label: "Likes",
        backgroundColor: "#FFC700",
        data: data1,
        barThickness: 40,
        categoryPercentage: 0.5,
        borderRadius: {
          bottomLeft: 10,
          bottomRight: 10,
        },
      },
      {
        label: "Shares",
        backgroundColor: "#CCA004",
        data: data1,
        barThickness: 40,
      },
      {
        label: "Comments",
        backgroundColor: " #8C6D00",
        data: data1,
        barThickness: 40,
        borderRadius: {
          topLeft: 10,
          topRight: 10,
          bottomLeft: 0,
          bottomRight: 0,
        },
      },
    ],
  };

  const options: any = {
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          color: "#989898",
          fontSize: 12,
          truncate: true,
        },
      },
      y: {
        grid: {
          color: (context: any) =>
            context.tick.value === 0 ? "#484848" : "#292929",
        },
        stacked: true,
        ticks: {
          beginAtZero: true,
          padding: 15,
          color: "#666666",
          fontSize: 11,
          stepSize: 5,
          maxTicksLimit: 5,
          callback: function (value: number) {
            if (value === 0) return value;
            const units = ["K", "M", "B", "T"];
            const unitIndex = Math.floor(Math.log10(value) / 3);
            const unit = units[unitIndex];
            const formattedValue = (
              value / Math.pow(1000, unitIndex)
            ).toFixed(1);
            return `${formattedValue}${unit}`;
          },
        },
      },
    },
    plugins: {
      legend: {
        fullSize: true,
        display: true,
        position: "top",
        align: "start",
        labels: {
          boxWidth: 6,
          boxHeight: 6,
          usePointStyle: true,
          useBorderRadius: true,
          padding: 15,
          pointStyle: "circle",
          color: "#fff",
          font: {
            family: "Montserrat",
            size: 12,
            weight: "normal",
            lineHeight: 1.3,
          },
        },
      },
    },
  };

    const legendMargin = {
    id: "legendMargin",
    beforeInit(chart: any, legend?: any, options?: any) {
      const fitValue = chart.legend.fit;  
      chart.legend.fit = () => {
        fitValue.bind(chart.legend)();
        return (chart.legend.height += 24);
      };
    },
  };

  return (
    <div className="chart-container">
      <div
        style={{
          height: "270px",
          overflowX: "scroll",
          overflowY: "hidden",
        }}
      >
        <div style={{ width: "1000px", minWidth: "100%" }}>
          <Bar
            style={{ width: "1000px", height: "260px", marginLeft: "-12px" }}
            data={chartData}
            options={options}
            plugins={[legendMargin]}
          />
        </div>
      </div>
    </div>
  );
};

export default GroupedBarChart;
