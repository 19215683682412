import React from "react";
import { Select } from "antd";
import "./TagsGroup.scss"; // Import the SCSS file

const { Option } = Select;

interface NicheTagsProps {
  placeholder: string;
  defaultValue: string[];
  options: string[];
  onChange: (selectedItems: string[]) => void;
}

const TagsGroup: React.FC<NicheTagsProps> = ({
  placeholder,
  defaultValue,
  options,
  onChange,
}) => {
  return (
    <Select
      mode="tags"
      className="custom-tags-select"
      popupClassName="custom-dropdown-select"
      style={{
        width: "100%",
      }}
      placeholder={placeholder}
      suffixIcon={null}
      defaultValue={defaultValue}
      onChange={onChange}
    >
      {options.map((option, index) => (
        <Option key={index} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  );
};

export default TagsGroup;
