import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import { WordCloudController, WordElement } from "chartjs-chart-wordcloud";

Chart.register(WordCloudController, WordElement);

const WordCloudChart: any = ({ data }: any) => {
  const canvasRef: any = useRef(null);

  useEffect(() => {
    if (canvasRef.current) {
      const canvas: any = canvasRef.current;

      canvas.width = canvas.parentNode.clientWidth;
      canvas.height = canvas.parentNode.clientHeight;

      const ctx = canvasRef.current.getContext("2d");

      new Chart(ctx, {
        type: WordCloudController.id,
        data: {
          labels: data.map((d: any) => d.text),
          datasets: [
            {
              color: data.map((d: any) => (d.value > 50 ? "#1F1F1F" : "#808080")),
              label: "DS",
              data: data.map((d: any) => d.value),
            },
          ],
        },
        options: {
          responsive: true,
          // maintainAspectRatio: false,
          plugins: {
            legend: false as any,
          },
        },
      });
    }
  }, []);

  return (
    <div className="flex w-full justify-center mt-[16px] items-center">
      <div className="w-[516.04px] h-[304.57px] flex justify-center items-center">
        <canvas ref={canvasRef}></canvas>
      </div>
    </div>
  );
};

export default WordCloudChart;
