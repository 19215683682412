import React, { useState, ReactNode } from "react";
import infoCircle from "../../../assets/svg/infoCircle.svg";
import GetSuggestions from "../GetSuggestions";
import "./PickCreators.scss";
import SearchField from "../../SearchField/SearchField";
import { Filter, UpDownArrow } from "../../../Icons";
import dropdownIcon from "../../../assets/svg/downArrowBlack.svg";
import PrimarySwitch from "../../Switch/PrimarySwitch";
import { Checkbox, Dropdown, Input, Menu, Tag } from "antd";
import CustomSelect from "../../CustomSelect/CustomSelect";
import PickCreatorsTable from "../PickCreatorsTable/PickCreatorsTable";
import type { ColumnsType } from "antd/es/table";
import logo from "../../../assets/png/cre1.png";
import logo2 from "../../../assets/png/cre2.png";
import logo3 from "../../../assets/png/cre3.png";
import logo4 from "../../../assets/png/cre4.png";
import logo5 from "../../../assets/png/cre5.png";
import logo6 from "../../../assets/png/cre6.png";
import logo7 from "../../../assets/png/cre7.png";
import logo8 from "../../../assets/png/cre8.png";
import FilterDrawer from "../FilterDrawer/FilterDrawer";
import GetSuggestionTab from "../../Common/GetSuggestionTab";

interface UserData {
  profileImage: string;
  userName: string;
  userId: string;
}
interface CreatorData {
  key: string;
  name: UserData;
  likes: number;
  engagements: number;
  engagementRate: number;
  ctr: number;
}

type VisibleColumns = {
  name: boolean;
  likes: boolean;
  engagements: boolean;
  engagementRate: boolean;
  ctr: boolean;
};

const PickCreators: React.FC = () => {
  const [checked, setChecked] = useState<boolean>(false);
  const [paid, setPaid] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("1");
  const [checked2, setChecked2] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [hoveredRow, setHoveredRow] = useState<number | any>(null);
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [checkedState, setCheckedState] = useState(
    selectedRowsData.map(() => true)
  );
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<VisibleColumns>({
    name: true,
    likes: true,
    engagements: true,
    engagementRate: true,
    ctr: true,
  });

  const handleColumnToggleChange = (columnKey: keyof VisibleColumns) => {
    setVisibleColumns((prevState) => ({
      ...prevState,
      [columnKey]: !prevState[columnKey],
    }));
  };

  const handleRowSelect = (key: string, rowData: any) => {
    setSelectedRows((prevSelectedRows) => {
      const updatedSelectedRows = prevSelectedRows.includes(key)
        ? prevSelectedRows.filter((item) => item !== key)
        : [...prevSelectedRows, key];

      const updatedData = data.map((item: any) =>
        item.key === key ? { ...item, checked: !item.checked, paid } : item
      );

      const updatedSelectedRowsData = updatedSelectedRows.includes(key)
        ? [...selectedRowsData, rowData]
        : selectedRowsData.filter((item) => item.key !== rowData.key);
      setData(updatedData);
      setSelectedRowsData(updatedSelectedRowsData);

      return updatedSelectedRows;
    });
  };

  const onChange = (checked?: boolean) => {
    setChecked((prev: boolean) => !prev);
  };

  const handleTextCheck = (checked?: boolean) => {
    setChecked2((prev) => !prev);
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "328px",
      render: (name: any, record: any, index: any) => {
        const isSelected = selectedRows.includes(record.key);

        return (
          <div
            onMouseEnter={() => setHoveredRow(record.key)}
            onMouseLeave={() => setHoveredRow(null)}
            className="flex items-center gap-3"
          >
            {hoveredRow === record.key ? (
              <span className="w-[32px] avatarCheckbox h-[32px] flex justify-center items-center bg-primary rounded-[30px]">
                <Checkbox
                  checked={isSelected}
                  className={`bg-${
                    isSelected ? "bg-primary" : "your-normal-color"
                  }`}
                  rootClassName="secondary-checkbox"
                  onClick={(e) => {
                    handleCheckboxChange(index);
                    e.stopPropagation();
                    handleRowSelect(record.key, record);
                  }}
                />
              </span>
            ) : (
              <div className="rounded-[50%]">
                <img
                  className="min-w-[32px] max-w-[32px] min-h-[32px] max-h-8 object-contain rounded-[50%]"
                  src={name.profileImage}
                  alt=""
                />
              </div>
            )}

            <div className="flex flex-col gap-0.5">
              <p className="text-primary text-sm font-semibold leading-[120%]">
                {name.userName}
              </p>
              <p className="text-link_hover text-xs font-medium leading-[130%]">
                {name.userId}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      title: "Likes",
      dataIndex: "likes",
      key: "likes",
      width: "168px",
      className: "text-dark_90 text-sm font-normal leading-[140%]",
      render: (_: any, { likes }: any) => (
        <>
          <span className="uppercase">{likes + "k"}</span>
        </>
      ),
    },
    {
      title: "Engagements",
      dataIndex: "engagements",
      key: "engagements",
      width: "168px",
      className: "text-dark_90 text-sm font-normal leading-[140%]",
      render: (_: any, { engagements }: any) => (
        <>
          <span className="uppercase">{engagements + "k"}</span>
        </>
      ),
    },
    {
      title: "Engagement Rate",
      dataIndex: "engagementRate",
      key: "engagementRate",
      width: "168px",
      className: "text-dark_90 text-sm font-normal leading-[140%]",
      render: (_: any, { engagementRate }: any) => (
        <>
          <span className="uppercase">{engagementRate + "%"}</span>
        </>
      ),
    },
    {
      title: "ctr",
      dataIndex: "ctr",
      key: "ctr",
      width: "152px",
      className: "text-dark_90 text-sm font-normal leading-[140%]",
      render: (_: any, { ctr }: any) => (
        <>
          <span className="uppercase">{ctr + "%"}</span>
        </>
      ),
    },
  ].filter(Boolean);

  const [data, setData] = useState<any>([
    {
      key: "1",
      name: {
        profileImage: logo,
        userName: "Vincent Nate",
        userId: "@annan",
      },
      likes: 22.4,
      engagements: 5,
      engagementRate: 0.9,
      ctr: 0.6,
      checked: false,
      paid: false,
    },
    {
      key: "2",
      name: {
        profileImage: logo2,
        userName: "Haylie Stanton",
        userId: "@hayliestanton",
      },
      likes: 50,
      engagements: 100,
      engagementRate: 1,
      ctr: 2,
      checked: false,
      paid: false,
    },
    {
      key: "3",
      name: {
        profileImage: logo3,
        userName: "Jiemba Talia",
        userId: "@jiemba",
      },
      likes: 55.2,
      engagements: 42,
      engagementRate: 0.5,
      ctr: 0.3,
      checked: false,
      paid: false,
    },
    {
      key: "4",
      name: {
        profileImage: logo4,
        userName: "Tatiana Herwitz",
        userId: "@tatiiiiiii",
      },
      likes: 10,
      engagements: 2,
      engagementRate: 0.1,
      ctr: 1,
      checked: false,
      paid: false,
    },
    {
      key: "5",
      name: {
        profileImage: logo5,
        userName: "Corey Siphron",
        userId: "@coreysiphron",
      },
      likes: 120,
      engagements: 124,
      engagementRate: 0.2,
      ctr: 0.4,
      checked: false,
      paid: false,
    },
    {
      key: "6",
      name: {
        profileImage: logo6,
        userName: "Martin Schleifer",
        userId: "@scorcessemartin",
      },
      likes: 101,
      engagements: 3.5,
      engagementRate: 0.3,
      ctr: 2,
      checked: false,
      paid: false,
    },
    {
      key: "7",
      name: {
        profileImage: logo7,
        userName: "Terry Mango",
        userId: "@mangojuice",
      },
      likes: 345,
      engagements: 23,
      engagementRate: 1,
      ctr: 2,
      checked: false,
      paid: false,
    },
    {
      key: "8",
      name: {
        profileImage: logo8,
        userName: "Brandon Carder",
        userId: "@brandon",
      },
      likes: 87,
      engagements: 1,
      engagementRate: 0.6,
      ctr: 0.7,
      checked: false,
      paid: false,
    },
  ]);

  const menu = (
    <Menu className="bg-white p-4">
      {columns.map((item: any) => (
        <Menu.Item key={item.key}>
          <div className="w-full h-fit flex justify-between ">
            <p className="capitalize text-sm not-italic font-normal leading-[140%]">
              {item.key}
            </p>
            <PrimarySwitch
              className="primary-switch ml-3"
              defaultChecked
              onChange={() => handleColumnToggleChange(item.key)}
            />
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleTagClose = (index: number) => {
    const updatedTags = [...selectedRowsData];
    updatedTags.splice(index, 1);
    setSelectedRowsData(updatedTags);

    const updatedCheckedState = [...checkedState];
    updatedCheckedState[index] = false;
    setCheckedState(updatedCheckedState);
  };

  const handleClearAllTags = () => {
    setSelectedRowsData([]);
    setCheckedState([]);
  };

  const handleCheckboxChange = (key: string) => {
    setData((prevData: any) => {
      return prevData.map((item: any) => {
        if (item.key === key) {
          const updatedItem = { ...item, checked: !item.checked };

          if (updatedItem.checked) {
            setSelectedRows((prevSelectedRows) => [...prevSelectedRows, key]);
            setSelectedRowsData((prevSelectedRowsData) => [
              ...prevSelectedRowsData,
              updatedItem,
            ]);
          } else {
            setSelectedRows((prevSelectedRows) =>
              prevSelectedRows.filter((item) => item !== key)
            );
            setSelectedRowsData((prevSelectedRowsData) =>
              prevSelectedRowsData.filter((item) => item.key !== key)
            );
          }

          return updatedItem;
        }
        return item;
      });
    });
  };

  const visibleColumnsArray = columns.filter(
    (column) => visibleColumns[column?.key as keyof VisibleColumns]
  );

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <div className="w-full mx-auto p-10 md:w-5/6 lg:w-5/6">
      {!paid && (
        <GetSuggestions
          title="Having trouble picking the right people?"
          paid={paid}
          setPaid={setPaid}
          setActiveTab={setActiveTab}
        />
      )}

      <div className="border flex justify-between rounded-2xl border-solid border-primary md:flex-col-reverse lg:flex-row ">
        <div className="p-8">
          <h2 className="text-primary text-[19px] font-semibold leading-[120%] tracking-[-1px] mb-5">
            Select your creators
          </h2>
          <div className="flex gap-3 items-center mb-3">
            <span
              className={`${
                checked ? "text-dark_90" : "text-gray_60"
              } text-sm font-semibold leading-[120%]`}
            >
              Open campaign
            </span>
            <PrimarySwitch
              className="primary-switch"
              defaultChecked
              onChange={onChange}
            />
            <span
              className={`${
                !checked ? "text-dark_90" : "text-gray_60"
              } text-sm font-semibold leading-[120%]`}
            >
              Closed campaign
            </span>
          </div>
          <div className="flex gap-1 items-center mb-8">
            <img src={infoCircle} alt="info circle" />
            <p className="text-link_hover text-[13px] font-medium leading-[130%]">
              By making campaigns open, other creators would be able to apply to
              your campaign.
            </p>
          </div>
          {paid && (
            <div className="w-fit h-11 p-1 mt-2 mb-6 bg-amber-100 rounded-[50px] border border-yellow-600 justify-start items-start gap-1 inline-flex">
              <GetSuggestionTab
                label="Explore creators"
                active={activeTab === "1"}
                onClick={() => {
                  handleTabClick("1");
                }}
                color="zinc-900"
                icon={""}
                bgColor="yellow-400"
              />
              <GetSuggestionTab
                label="Curated suggestions"
                active={activeTab === "2"}
                onClick={() => {
                  handleTabClick("2");
                }}
                color="zinc-900"
                bgColor="yellow-400"
                icon={
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1131_5983)">
                      <path
                        d="M8 0L8.45322 2.75156C8.85859 5.21255 10.7874 7.14141 13.2484 7.54678L16 8L13.2484 8.45322C10.7874 8.85859 8.85859 10.7874 8.45322 13.2484L8 16L7.54678 13.2484C7.14141 10.7874 5.21255 8.85859 2.75156 8.45322L0 8L2.75156 7.54678C5.21255 7.14141 7.14141 5.21255 7.54678 2.75156L8 0Z"
                        fill="#1F1F1F"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1131_5983">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                }
              />
            </div>
          )}
          {activeTab === "1" ? (
            <>
              <div className="flex items-center justify-between mb-8 gap-4 flex-wrap">
                <SearchField inputClassName="w-[340px]" />
                <div className="flex gap-3">
                  <button
                    onClick={showDrawer}
                    className="flex items-center gap-2 border px-4 py-2.5 rounded-md border-solid border-input_border hover:bg-black_button_hover hover:text-primary transition-[background] duration-300 ease-[ease]"
                  >
                    <Filter />
                    <span className="text-xs font-semibold leading-[130%]">
                      Filters
                    </span>
                  </button>
                  <CustomSelect
                    className="select-custom"
                    placeholder={
                      <div className="flex items-center gap-2">
                        <UpDownArrow />
                        <span className="text-primary text-[13px] font-semibold leading-[130%]">
                          Sort
                        </span>
                      </div>
                    }
                    style={{ width: 112 }}
                    suffixIcon={
                      <img className="" src={dropdownIcon} alt="campaignAdd" />
                    }
                    options={[
                      { value: "Popular", label: "Popular" },
                      { value: "Budget", label: "Budget" },
                    ]}
                  />
                </div>
              </div>
              <div className="flex items-center gap-3 mb-8">
                <PrimarySwitch
                  className="primary-switch"
                  defaultChecked
                  onChange={handleTextCheck}
                />
                <span
                  className={`${
                    !checked2 ? "text-primary" : "text-gray_60"
                  } text-center text-sm font-medium leading-[140%]`}
                >
                  Show only registered users
                </span>
              </div>
              <div className="relative">
                <PickCreatorsTable
                  tableData={data}
                  tableColumns={visibleColumnsArray}
                  pagination={false}
                />
                <span className="w-fit absolute top-[10px] right-0">
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    placement="bottomLeft"
                  >
                    <span className="clickable-svg cursor-pointer">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="2.75"
                          y="2.75"
                          width="10.5"
                          height="10.5"
                          rx="1.25"
                          stroke="#808080"
                          stroke-width="1.5"
                        />
                        <line
                          x1="2"
                          y1="6.25"
                          x2="14"
                          y2="6.25"
                          stroke="#808080"
                          stroke-width="1.5"
                        />
                        <path
                          d="M8.23047 5.69141L8.23047 13.5375"
                          stroke="#808080"
                          stroke-width="1.5"
                        />
                      </svg>
                    </span>
                  </Dropdown>
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center justify-between mb-8">
                <div className="flex gap-4">
                  <Input
                    className="border-[#989898] border hover:border-[#989898] focus:border-input_focus"
                    placeholder="No. creators to shortlist"
                  />
                  <Input
                    className="border-[#989898] border hover:border-[#989898] focus:border-input_focus"
                    placeholder="No. creators to include"
                  />
                  <button className="px-4 py-2 text-[12px] rounded-r-lg  hover:bg-black_button_hover text-white hover:text-primary font-[600]  rounded-md bg-primary transition-[background] duration-300 ease-[ease]">
                    Optimize
                  </button>
                </div>
                <div className="flex gap-3">
                  <button
                    onClick={showDrawer}
                    className="flex items-center gap-2 border px-4 py-2.5 rounded-md border-solid border-input_border hover:bg-black_button_hover hover:text-primary transition-[background] duration-300 ease-[ease]"
                  >
                    <Filter />
                    <span className="text-xs font-semibold leading-[130%]">
                      Filters
                    </span>
                  </button>
                  <CustomSelect
                    className="select-custom"
                    placeholder={
                      <div className="flex items-center gap-2">
                        <UpDownArrow />
                        <span className="text-primary text-[13px] font-semibold leading-[130%]">
                          Sort
                        </span>
                      </div>
                    }
                    style={{ width: 112 }}
                    suffixIcon={
                      <img className="" src={dropdownIcon} alt="campaignAdd" />
                    }
                    options={[
                      { value: "Popular", label: "Popular" },
                      { value: "Budget", label: "Budget" },
                    ]}
                  />
                </div>
              </div>
              <div className="flex items-center gap-3 mb-8">
                <PrimarySwitch
                  className="primary-switch"
                  defaultChecked
                  onChange={handleTextCheck}
                />
                <span
                  className={`${
                    !checked2 ? "text-primary" : "text-gray_60"
                  } text-center text-sm font-medium leading-[140%]`}
                >
                  Show only registered users
                </span>
              </div>
              <div className="relative">
                <PickCreatorsTable
                  tableData={data}
                  tableColumns={visibleColumnsArray}
                />
                <span className="w-fit absolute top-[10px] right-0">
                  <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    placement="bottomLeft"
                  >
                    <span className="clickable-svg cursor-pointer">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="2.75"
                          y="2.75"
                          width="10.5"
                          height="10.5"
                          rx="1.25"
                          stroke="#808080"
                          stroke-width="1.5"
                        />
                        <line
                          x1="2"
                          y1="6.25"
                          x2="14"
                          y2="6.25"
                          stroke="#808080"
                          stroke-width="1.5"
                        />
                        <path
                          d="M8.23047 5.69141L8.23047 13.5375"
                          stroke="#808080"
                          stroke-width="1.5"
                        />
                      </svg>
                    </span>
                  </Dropdown>
                </span>
              </div>
            </>
          )}
        </div>
        {selectedRowsData.length > 0 && (
          <div className=" min-w-[200px] bg-[#FAFAFA] p-6 rounded-2xl ">
            <>
              <p className="text-[#666666] text-[11px] not-italic font-semibold leading-[100%]">
                {selectedRowsData.length} selected
              </p>
              {selectedRowsData.map((item, index) => (
                <div className="flex">
                  <Tag
                    closeIcon={
                      <span className="flex ml-1 w-3 h-3 justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M8.9989 3.00195L3.00391 8.99695"
                            stroke="#1F1F1F"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9 9.00125L3 3"
                            stroke="#1F1F1F"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    }
                    key={index}
                    className="tag-container mt-2 mr-0 rounded-[30px] flex justify-center items-center border border-[#989898] bg-[#EFEFEF] "
                    icon={
                      <span className="flex relative  ">
                        <img
                          className=" w-[26px] h-[26px] relative bg-[#EFEFEF]  rounded-2xl  mr-2 "
                          src={item.name.profileImage}
                          alt={item.name.userName}
                        />
                        {item.paid && (
                          <span className=" absolute flex justify-center items-center bottom-0 right-[2px] z-10 w-[14px] h-[14px] bg-[#D1A300]  border border-[#B18A00] rounded-[30px] ">
                            <span className="w-2.5 h-2.5 ">
                              <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="white"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5 0L5.28327 1.71972C5.53662 3.25784 6.74216 4.46338 8.28028 4.71673L10 5L8.28028 5.28327C6.74216 5.53662 5.53662 6.74216 5.28327 8.28028L5 10L4.71673 8.28028C4.46338 6.74216 3.25784 5.53662 1.71972 5.28327L0 5L1.71972 4.71673C3.25784 4.46338 4.46338 3.25784 4.71673 1.71972L5 0Z"
                                  fill="white"
                                />
                              </svg>
                            </span>
                          </span>
                        )}
                      </span>
                    }
                    closable
                    onClose={() => handleTagClose(index)}
                  >
                    <span className="flex">
                      <p className="  text-[13px] not-italic font-medium leading-[130%]">{` ${item.name.userName}	`}</p>
                    </span>
                  </Tag>
                </div>
              ))}
              <button
                onClick={handleClearAllTags}
                className="text-[13px] not-italic cursor-pointer hover:text-link_hover font-semibold leading-[130%] underline mt-4"
              >
                Clear all
              </button>
            </>
          </div>
        )}

        <FilterDrawer openDrawer={openDrawer} onClose={onClose} />
      </div>
    </div>
  );
};

export default PickCreators;
