import React, { useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import EyeIcon from "../../assets/svg/eye.svg";
import EyeCloseIcon from "../../assets/svg/eyeClose.svg";
import { Link, useNavigate } from "react-router-dom";

interface FormValues {
  brandName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const validationSchema = Yup.object().shape({
  brandName: Yup.string().required("Brand name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm password is required"),
});

const SignInForm: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = (values: FormValues) => {
    if (validationSchema.isValidSync(values)) {
      navigate("/onboard");
    }
  };

  return (
    <div className="flex flex-1 flex-col justify-center max-w-md w-[360px]">
      <Formik
        initialValues={{
          brandName: "",
          email: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors }) => (
          <Form>
            <div className="flex flex-col space-y-2 text-left">
              <h2 className="text-[29px] font-bold leading-[35px]">Join us,</h2>
              <p className="text-sm font-medium leading-[18px] tracking-[0px] text-left text-primary">
                Start your marketing journey with us today !
              </p>
            </div>
            <div className="flex flex-col max-w-md">
              <Field
                name="brandName"
                placeholder="Brand name"
                type="text"
                className={`text-sm text-primary px-4 py-3 mt-6 rounded-lg w-full bg-white focus:outline-none ${
                  errors.brandName ? "border-input_error" : "border-[#989898]"
                } focus:border-input_focus border placeholder-primary`}
              />
              <ErrorMessage
                name="brandName"
                component="div"
                className="text-red-600 text-xs font-normal leading-none mt-1"
              />

              <Field
                name="email"
                placeholder="Email"
                type="text"
                className={`text-sm text-primary mt-5 px-4 py-3 rounded-lg w-full bg-white focus:outline-none ${
                  errors.email ? "border-input_error" : "border-[#989898]"
                } focus:border-input_focus border placeholder-primary`}
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-600 text-xs font-normal leading-none mt-1"
              />
              <div
                className={`flex items-center mt-5 border border-solid ${
                  showPassword ? "border-input_focus" : " border-[#989898]"
                } rounded-lg px-4 py-3 w-full bg-white ${
                  errors.password && "border-input_error"
                }`}
              >
                <Field
                  name="password"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  className="text-sm text-primary w-full bg-white focus:outline-none placeholder-primary"
                />
                <div
                  onClick={togglePasswordVisibility}
                  className="ml-2 text-sm leading-5 cursor-pointer"
                >
                  <img
                    src={showPassword ? EyeCloseIcon : EyeIcon}
                    alt="Toggle Password Visibility"
                    className="h-5 w-5 rounded bg-white text-purple-700 cursor-pointer"
                  />
                </div>
              </div>
              <ErrorMessage
                name="password"
                component="div"
                className="text-red-600 text-xs font-normal leading-none mt-1"
              />

              <div
                className={`flex items-center mt-5 border border-solid ${
                  showConfirmPassword
                    ? "border-input_focus"
                    : " border-[#989898]"
                } rounded-lg px-4 py-3 w-full bg-white ${
                  errors.confirmPassword && "border-input_error"
                }`}
              >
                <Field
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  className="text-sm text-primary w-full bg-white focus:outline-none placeholder-primary"
                />
                <div
                  onClick={toggleConfirmPasswordVisibility}
                  className="ml-2 text-sm leading-5 cursor-pointer"
                >
                  <img
                    src={showConfirmPassword ? EyeCloseIcon : EyeIcon}
                    alt="Toggle Confirm Password Visibility"
                    className="h-5 w-5 rounded bg-white text-purple-700 cursor-pointer"
                  />
                </div>
              </div>
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="text-red-600 text-xs font-normal leading-none mt-1"
              />

              <button
                type="submit"
                className="flex hover:bg-black_button_hover hover:text-primary text-sm font-semibold text-white mt-8 w-full items-center justify-center bg-primary text-white px-3 py-2 rounded-lg font-medium h-[49px]"
              >
                Sign up
              </button>
              <div className="mt-6 w-full text-center">
                <p className="text-sm text-[#2F3032]">
                  Don't have an account?
                  <Link
                    to="/login"
                    className="text-sm ml-1 hover:text-link_hover font-semibold text-primary underline underline-offset-2"
                  >
                    Sign in
                  </Link>
                </p>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignInForm;
