import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import logo from "../assets/png/logo.png";
import Select from "../components/Select/Select";
import "../styles/Onboarding.css";
import { useNavigate } from "react-router-dom";

const Onboarding: React.FC = () => {
  const options: string[] = [
    "Industry/ Niche",
    "Beauty/ Fashion",
    "Healthcare",
    "Software/ Productivity",
    "Miscellaneous",
    "Other",
  ];

  const navigate = useNavigate();
  const [websiteURLError, setWebsiteURLError] = useState<string | null>(null);
  const [websiteInputFocused, setWebsiteInputFocused] =
    useState<boolean>(false);
  const validateURL = (url: string) => {
    return url.startsWith("http://") || url.startsWith("https://");
  };

  const handleSubmit = (values: any) => {
    navigate("/dashboard");
  };

  return (
    <div className="w-full h-full">
      <div className="flex pt-12 pb-10 flex-col items-center justify-center text-primary bg-gradient-yellow bg-gradient-black">
        <div className="flex justify-center items-center w-full  cursor-pointer">
          <img src={logo} alt="Logo" className="w-28 h-8" />
        </div>
        <h1 className="text-[29px] font-extrabold  mt-6">
          Welcome, Netflix India
        </h1>
        <p className="text-[16px] mt-[10px text-primary font-medium">
          Please fill out the form down below so that we could get to know you
          more.
        </p>
      </div>
      <div className="w-full h-full flex justify-center items-center mt-10">
        <div className="w-[504px] h-[364px] rounded-[16px] border-1 border border-gray-500 p-[32px]">
          <h3 className="text-lg font-semibold leading-6 text-left">
            Tell us more about you
          </h3>
          <p className="text-sm mt-2 font-medium leading-4 mb-[24px] tracking-normal text-left text-[#484848]">
            We’d like to know more to give you the ultimate marketing
            experience.
          </p>
          <Select options={options} />
          <Formik
            initialValues={{ websiteURL: "" }}
            onSubmit={handleSubmit}
            validate={(values) => {
              const errors: any = {};
              if (!validateURL(values.websiteURL)) {
                errors.websiteURL = "Please enter a valid URL.";
                setWebsiteURLError("Please enter a valid URL.");
              } else {
                setWebsiteURLError(null);
              }
              return errors;
            }}
          >
            <Form>
              <div
                className={`border ${websiteURLError && "border-red-500"} ${
                  websiteInputFocused && "border-input_focus"
                } mt-4 p-2 leading-4 h-12 rounded-[8px] border-input_border border-solid`}
              >
                <p className="text-xs font-semibold leading-4 tracking-normal text-left text-[#666666]">
                  Website URL
                </p>
                <Field
                  name="websiteURL"
                  onFocus={() => setWebsiteInputFocused(true)}
                  onBlur={() => setWebsiteInputFocused(false)}
                  placeholder="https://"
                  className="text-sm font-medium leading-4 w-full focus:border-input_focus tracking-normal text-left input-focus-visible placeholder-primary input-default focus:input-focus hover:input-hover focus-visible:input-focus-visible"
                />
                <ErrorMessage
                  name="websiteURL"
                  component="div"
                  className="text-xs text-red-500 mt-3"
                />
              </div>
              <div className="w-full">
                <button
                  type="submit"
                  className="flex hover:bg-black_button_hover hover:text-primary text-sm font-semibold text-white mt-8 w-full items-center justify-center bg-primary text-white px-3 py-2 rounded-lg font-medium h-[49px]"
                >
                  Continue
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
