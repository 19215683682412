import React, { useState } from "react";
import yellowStarCircle from "../../assets/svg/yellowStarCircle.svg";
import ModalPopup from "../Modal/Modal";
import bbsCoins from "../../assets/svg/bbsCoins.svg";
import { Button } from "antd";
import { BbsCoins } from "../../Icons";
import CustomDrawer from "../Drawer/CustomDrawer";
import { title } from "process";

interface GetSuggestionsProps {
  paid: boolean;
  title: string;
  setPaid: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

const GetSuggestions: React.FC<GetSuggestionsProps> = ({
  paid,
  title,
  setPaid,
  setActiveTab,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setPaid(true);
    setActiveTab("2");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const footerBtn = (
    <div className="flex justify-center gap-5">
      <button
        onClick={handleCancel}
        className="px-6 py-3 h-[41px] flex justify-center items-center rounded-md border border-solid border-black_button_hover text-primary text-[13px] font-[600] leading-[130%] hover:bg-black_button_hover hover:text-primary transition-[background] duration-300 ease-[ease]"
      >
        Cancel
      </button>
      <button
        onClick={handleOk}
        className="flex justify-center items-center gap-2 h-[41px] bg-black_button_hover font-[600] px-6 py-3 rounded-md border border-solid border-black_button_hover hover:bg-white hover:text-primary transition-[background] duration-300 ease-[ease]"
      >
        <BbsCoins />
        <span className=" text-[13px]  font-[600] leading-[130%] ml-2">
          Pay 100 coins
        </span>
      </button>
    </div>
  );

  return (
    <div className="flex items-center justify-between flex-wrap gap-4 bg-primary rounded-2xl p-4 w-full mb-3">
      <div className="flex items-center  gap-3">
        <img src={yellowStarCircle} alt="icon" />
        <div className="flex flex-col gap-1">
          <p className="text-white text-base not-italic font-semibold leading-[120%] tracking-[-1px]">
            {title}{" "}
          </p>
          <p className="text-white text-sm font-normal leading-[140%]">
            Let us{" "}
            <span className="text-black_button_hover text-sm not-italic font-semibold leading-[140%]">
              do it for you
            </span>
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <button
          onClick={showModal}
          className="border rounded-md border-solid border-white px-4 py-2.5 h-9 text-white text-xs font-semibold leading-[130%] hover:bg-black_button_hover hover:border-black_button_hover hover:text-primary transition-[background]  duration-300 ease-[ease]"
        >
          Get suggestions
        </button>
        <span className="text-white text-right text-xs font-normal leading-[130%]">
          *Requires 100 BBS coins
        </span>
      </div>
      <ModalPopup
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        footer={footerBtn}
        width={"680px"}
      >
        <img
          className="max-w-[218px] w-full h-auto mx-auto mb-8"
          src={bbsCoins}
          alt="bbs coins"
        />
        <h4 className="text-primary text-center text-[19px] font-semibold leading-[120%] tracking-[-1px] mb-3">
          100 BBS Coins required, would you like to continue ?
        </h4>
        <p className="max-w-[384px] mx-auto text-primary text-center text-sm font-normal leading-[140%] mb-6">
          Once the coins are used up, it’s no longer redeemable, do you like to
          continue ?
        </p>
      </ModalPopup>
    </div>
  );
};

export default GetSuggestions;
