import React, { useEffect, useState } from "react";
import logo from "../../assets/png/logo.png";
import { Button, message, Steps, theme } from "antd";
import "./CreateCampaignHead.scss";
interface Step {
  title: string;
  content: React.ReactNode;
}

interface CreateCampaignHeadProps {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}

const steps: Step[] = [
  {
    title: "Basic details",
    content: "First-content",
  },
  {
    title: "Select platform & medium",
    content: "Second-content",
  },
  {
    title: "Pick creators",
    content: "Last-content",
  },
];

const CreateCampaignHead: React.FC<CreateCampaignHeadProps> = ({
  current,
  setCurrent,
}) => {
  const { token } = theme.useToken();
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  const [scrolled, setScrolled] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderIcon = (index: any) => {
    if (index < current) {
      return (
        <span className="flex w-6 h-6 flex-col rounded-[12px] justify-center items-center gap-2 shrink-0 bg-primary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="8"
            viewBox="0 0 11 8"
            fill="none"
          >
            <path
              d="M1 3.99958L4.00084 6.99916L10 1"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      );
    }
    return null;
  };
  return (
    <>
      <div
        className={`sticky z-10  ${
          scrolled ? "top-[-100px]" : "top-0"
        } transition-[top] duration-200 ease-[ease-in-out]`}
      >
        <div
          className={`p-[32px] pb-[42px] relative text-primary bg-gradient-yellow border-b-2 border-solid border-b-primary `}
        >
          {current > 0 && (
            <button
              onClick={prev}
              className=" cursor-pointer h-[29px] absolute top-[32px] left[32px] px-2.5 py-1.5 bg-white rounded-[14.50px] border border-stone-900 justify-start items-center gap-0.5 inline-flex"
            >
              <div className="w-3 h-3 flex justify-center items-center">
                <svg
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.75 10L4.25 6.5L7.75 3"
                    stroke="#1F1F1F"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="text-primary text-[13px] not-italic font-semibold leading-[130%]">
                Back
              </div>
            </button>
          )}
          <div className="flex-col flex items-center justify-center">
            <div className="flex justify-center items-center w-full cursor-pointer">
              <img src={logo} alt="Logo" className="w-28 h-8" />
            </div>
            <h1 className="text-[29px] mt-[32px] not-italic font-[700] leading-[120%] tracking-[-1px]">
              Create campaign
            </h1>
            <div className=" w-3/5 mt-[16px] steps-head max-w-[600px]">
              <Steps
                className="create-campaign-steps"
                current={current}
                size="small"
                labelPlacement={"horizontal"}
                // responsive
              >
                {items.map((item, index) => (
                  <Steps.Step
                    style={{ width: "fit-content" }}
                    key={index}
                    title={item.title}
                    icon={renderIcon(index)}
                  />
                ))}
              </Steps>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCampaignHead;
