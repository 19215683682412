import React, { SVGProps } from "react";

export const SearchIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={9.84394}
      cy={9.84394}
      r={5.99237}
      stroke="#1F1F1F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0117 14.3242L16.3611 16.6675"
      stroke="#1F1F1F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Filter: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5 11.3333C8.96024 11.3333 9.33333 11.7064 9.33333 12.1667C9.33333 12.6269 8.96024 13 8.5 13H6.83333C6.3731 13 6 12.6269 6 12.1667C6 11.7064 6.3731 11.3333 6.83333 11.3333H8.5ZM11 7.16667C11.4602 7.16667 11.8333 7.53976 11.8333 8C11.8333 8.46024 11.4602 8.83333 11 8.83333H4.33333C3.8731 8.83333 3.5 8.46024 3.5 8C3.5 7.53976 3.8731 7.16667 4.33333 7.16667H11ZM13.5 3C13.9602 3 14.3333 3.3731 14.3333 3.83333C14.3333 4.29357 13.9602 4.66667 13.5 4.66667H1.83333C1.3731 4.66667 1 4.29357 1 3.83333C1 3.3731 1.3731 3 1.83333 3H13.5Z"
      fill="#1F1F1F"
    />
  </svg>
);

export const BbsCoins: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={21}
    height={21}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.0817 10.4987C20.0817 15.7914 15.7911 20.082 10.4984 20.082C5.20564 20.082 0.915039 15.7914 0.915039 10.4987C0.915039 5.20596 5.20564 0.915367 10.4984 0.915367C15.7911 0.915367 20.0817 5.20596 20.0817 10.4987Z"
      fill="#E79A25"
    />
    <path
      d="M10.4994 2.1924C5.91205 2.1924 2.19336 5.91108 2.19336 10.4985C2.19336 15.0858 5.91205 18.8047 10.4994 18.8047C15.0866 18.8047 18.8055 15.0858 18.8055 10.4985C18.8055 5.91108 15.0866 2.1924 10.4994 2.1924ZM10.4994 2.69412C14.8027 2.69412 18.3038 6.19514 18.3038 10.4985C18.3038 14.8018 14.8027 18.3028 10.4994 18.3028C6.19611 18.3028 2.69509 14.8018 2.69509 10.4985C2.69509 6.19514 6.19611 2.69412 10.4994 2.69412Z"
      fill="#DD7407"
    />
    <path
      d="M10.2699 8.20508H8.35254V9.51823H10.2699V8.20508Z"
      fill="#EA8B28"
    />
    <path
      d="M12.1583 9.57453C12.2519 9.66336 12.3602 9.73517 12.4785 9.78673C12.5997 9.83912 12.7304 9.86615 12.8624 9.86615C12.9945 9.86615 13.1251 9.83912 13.2463 9.78673C13.3642 9.73455 13.4725 9.6628 13.5665 9.57453C13.7559 9.38255 13.8622 9.12368 13.8622 8.85397C13.8622 8.58426 13.7559 8.3254 13.5665 8.13341C13.4732 8.0443 13.3648 7.97245 13.2463 7.92122C13.1251 7.86882 12.9945 7.8418 12.8624 7.8418C12.7304 7.8418 12.5997 7.86882 12.4785 7.92122C12.3597 7.9718 12.2512 8.04372 12.1583 8.13341C12.0624 8.22733 11.9863 8.33944 11.9343 8.46316C11.8823 8.58689 11.8555 8.71976 11.8555 8.85397C11.8555 8.98819 11.8823 9.12105 11.9343 9.24478C11.9863 9.36851 12.0624 9.48062 12.1583 9.57453Z"
      fill="#EA8B28"
    />
    <path
      d="M7.46201 4.9489L6.87793 6.125L10.3448 7.84673L10.9289 6.67063L7.46201 4.9489Z"
      fill="#EA8B28"
    />
    <path
      d="M13.4452 10.5515C11.7482 10.8663 11.2412 9.86523 11.2412 9.86523L10.3232 10.6563C10.5047 10.9197 10.7372 11.144 11.0069 11.3159C11.2766 11.4879 11.578 11.604 11.8934 11.6573L10.9647 16.0449H12.3113L13.4452 10.5515Z"
      fill="#EA8B28"
    />
    <path
      d="M9.85387 8.20508H7.93652V9.51823H9.85387V8.20508Z"
      fill="#FFE58A"
    />
    <path
      d="M11.7423 9.57453C11.8359 9.66336 11.9442 9.73517 12.0625 9.78673C12.1837 9.83912 12.3143 9.86615 12.4464 9.86615C12.5784 9.86615 12.7091 9.83912 12.8303 9.78673C12.9482 9.73455 13.0565 9.6628 13.1505 9.57453C13.3399 9.38255 13.4461 9.12368 13.4461 8.85397C13.4461 8.58426 13.3399 8.3254 13.1505 8.13341C13.0571 8.0443 12.9487 7.97245 12.8303 7.92122C12.7091 7.86882 12.5784 7.8418 12.4464 7.8418C12.3143 7.8418 12.1837 7.86882 12.0625 7.92122C11.9437 7.9718 11.8352 8.04372 11.7423 8.13341C11.6464 8.22733 11.5702 8.33944 11.5182 8.46316C11.4662 8.58689 11.4395 8.71976 11.4395 8.85397C11.4395 8.98819 11.4662 9.12105 11.5182 9.24478C11.5702 9.36851 11.6464 9.48062 11.7423 9.57453Z"
      fill="#FFE58A"
    />
    <path
      d="M7.04599 4.9489L6.46191 6.125L9.9288 7.84673L10.5129 6.67063L7.04599 4.9489Z"
      fill="#FFE58A"
    />
    <path
      d="M13.0292 10.5515C11.3321 10.8663 10.8251 9.86523 10.8251 9.86523L9.90723 10.6563C10.0887 10.9197 10.3211 11.144 10.5908 11.3159C10.8605 11.4879 11.162 11.604 11.4774 11.6573L10.5487 16.0449H11.8953L13.0292 10.5515Z"
      fill="#FFE58A"
    />
  </svg>
);

export const UpDownArrow: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.66699 5.33398L4.66699 3.33398L2.66699 5.33398"
      stroke="#1F1F1F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66732 12.6673V3.33398"
      stroke="#1F1F1F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33398 10.668L11.334 12.668L13.334 10.668"
      stroke="#1F1F1F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3333 3.33398V12.6673"
      stroke="#1F1F1F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LayoutIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={2.75}
        y={2.75}
        width={10.5}
        height={10.5}
        rx={1.25}
        stroke="gray"
        strokeWidth={1.5}
      />
      <path stroke="gray" strokeWidth={1.5} d="M2 6.25L14 6.25" />
      <path d="M8.23 5.691v7.846" stroke="gray" strokeWidth={1.5} />
    </svg>
  );
};

export const CloseIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.998 6.506l-11.99 11.99M18 18.503L6 6.5"
        stroke="#1F1F1F"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CloseIconSm: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.9989 3.00195L3.00391 8.99695"
      stroke="#1F1F1F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 9.00125L3 3"
      stroke="#1F1F1F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UpArrow: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={9}
    height={6}
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.70422 1.28994L8.70422 4.28994C8.84525 4.42981 8.94143 4.60851 8.9805 4.80326C9.01957 4.99801 8.99976 5.19998 8.92359 5.38342C8.84742 5.56686 8.71835 5.72347 8.55283 5.83327C8.38731 5.94307 8.19284 6.00109 7.99422 5.99994H1.99422C1.79559 6.00109 1.60113 5.94307 1.43561 5.83327C1.27009 5.72347 1.14101 5.56686 1.06485 5.38342C0.988681 5.19998 0.96887 4.99801 1.00794 4.80326C1.04701 4.60851 1.14319 4.42981 1.28422 4.28994L4.28422 1.28994C4.37718 1.19621 4.48778 1.12182 4.60964 1.07105C4.7315 1.02028 4.86221 0.994141 4.99422 0.994141C5.12623 0.994141 5.25694 1.02028 5.3788 1.07105C5.50066 1.12182 5.61126 1.19621 5.70422 1.28994Z"
      fill="#1F1F1F"
    />
  </svg>
);

export const DownArrow: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={9}
    height={6}
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.28418 4.71006L1.28419 1.71006C1.14316 1.57019 1.04697 1.39149 1.0079 1.19674C0.968834 1.00199 0.988647 0.800022 1.06481 0.616578C1.14098 0.433134 1.27005 0.27653 1.43557 0.166728C1.60109 0.056927 1.79556 -0.00109449 1.99418 6.04227e-05L7.99418 6.09472e-05C8.19281 -0.00109394 8.38728 0.0569276 8.5528 0.166729C8.71832 0.27653 8.84739 0.433135 8.92356 0.616579C8.99972 0.800023 9.01953 1.00199 8.98046 1.19674C8.94139 1.39149 8.84521 1.57019 8.70418 1.71006L5.70418 4.71006C5.61122 4.80379 5.50062 4.87818 5.37876 4.92895C5.2569 4.97972 5.1262 5.00586 4.99418 5.00586C4.86217 5.00586 4.73147 4.97972 4.60961 4.92895C4.48775 4.87818 4.37715 4.80379 4.28418 4.71006Z"
      fill="#1F1F1F"
    />
  </svg>
);
