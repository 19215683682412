import { Input } from 'antd';
import * as React from 'react';
import { SearchIcon } from '../../Icons';
import './SearchField.scss';

interface SearchFieldProps {
	inputClassName?: string;
}

const SearchField: React.FC<SearchFieldProps> = ({ inputClassName }) => {
	return (
		<div>
			<Input className={`primary-input ${inputClassName}`} prefix={<SearchIcon />} placeholder="Search" />
		</div>
	);
};

export default SearchField;
