import React from "react";
import Publishflyimage from "../../assets/png/publishfly.png";
import { useGlobalState } from "../../context/GlobalStateContext";
interface Medium {
  label: string;
  count: number;
}

interface Niche {
  name: string;
}

interface PreviewPublishProps {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}

const PreviewPublish: React.FC<PreviewPublishProps> = ({
  current,
  setCurrent,
}) => {
  const {
    selectedRowsData,
    setSelectedRowsData,
    selectedNichisData,
    setSelectedNichiData,
  } = useGlobalState();

  const [closed, setClosed] = React.useState<boolean>(false);
  const closedCampaignText = "Closed campaign:";
  const totalCostText = "Total cost";
  const totalCostValue = "₹ 3,00,000";

  // const mediums: Medium[] = [
  //   { label: "Instagram story", count: 1 },
  //   { label: "Instagram reel", count: 9 },
  //   { label: "Youtube short", count: 2 },
  // ];

  // const niches: Niche[] = [
  //   { name: "Fashion/ beauty" },
  //   { name: "Software products" },
  //   { name: "Education" },
  // ];

  // console.log(selectedRowsData, selectedNichisData);

  return (
    <div className="flex justify-center items-center w-full h-screen bg-neutral-50 pt-[88px]">
      <div className="relative w-full max-w-[955px] justify-between text-left flex flex-col sm:w-4/5 px-10 sm:px-0">
        <div className="flex justify-between flex-wrap gap-4 items-end">
          <div className="flex flex-col">
            <h2 className="text-[29px] not-italic font-bold leading-[120%] tracking-[-1px]">
              Preview and publish
            </h2>
            <span className="flex mt-2 whitespace-nowrap  text-sm not-italic font-medium leading-[140%] ">
              <p className="text-[#808080] ">{closedCampaignText}</p>
              <h6 className="text-primary ml-1  flex">
                <p className="font-[600] mr-1">45 creators</p> to be invited
              </h6>
            </span>
          </div>
          <div className="flex flex-col text-right">
            <p className="text-[#808080]  text-xs not-italic font-semibold leading-[130%]">
              {totalCostText}
            </p>
            <h6 className="text-primary text-[19px] not-italic font-semibold leading-[120%] tracking-[-1px] mt-0.5">
              {totalCostValue}
            </h6>
          </div>
        </div>
        <div className="flex justify-center items-center mt-6 w-full h-full">
          <div className=" left hidden sm:flex justify-between  max-w-[315px] w-full h-[490px] text-center flex-col py-10 px-5 text- items-center bg-[#FFC700] rounded-[16px] text-primary ">
            <div className="px-5">
              <h4 className=" text-[22px] not-italic font-semibold leading-[120%] tracking-[-1px]">
                Creator invitation
              </h4>
              <p className="text-sm not-italic font-normal leading-[140%] mt-2 ">
                Lorem Ipsum is simply dummy text of the printing.
              </p>
            </div>

            <img src={Publishflyimage} />
          </div>

          <div className="p-[30px] sm:p-9 md:p-16 h-[490px] max-w-[636px]  border-primary border relative  ml-1 w-full rounded-2xl text-left  ">
            <div className="hidden sm:block absolute left-[-18px] top-[25px]">
              <svg
                width="31"
                height="9"
                viewBox="0 0 31 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="5" cy="4.5" r="4" fill="#FAFAFA" stroke="#1F1F1F" />
                <circle
                  cx="26"
                  cy="4.5"
                  r="4"
                  fill="#FAFAFA"
                  stroke="#1F1F1F"
                />
                <path d="M8.5 4.5L22.5 4.5" stroke="#1F1F1F" />
              </svg>
            </div>
            <div className="hidden sm:block absolute left-[-18px] bottom-[25px]">
              <svg
                width="31"
                height="9"
                viewBox="0 0 31 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="5" cy="4.5" r="4" fill="#FAFAFA" stroke="#1F1F1F" />
                <circle
                  cx="26"
                  cy="4.5"
                  r="4"
                  fill="#FAFAFA"
                  stroke="#1F1F1F"
                />
                <path d="M8.5 4.5L22.5 4.5" stroke="#1F1F1F" />
              </svg>
            </div>
            <div className="flex flex-col  items-left border-b border-[#E2E2E2] pb-6  mt-6">
              <div className=" flex flex-col  justify-start w-full ">
                <h4 className=" text-[19px] not-italic font-semibold leading-[120%] tracking-[-1px] mr-3">
                  Black Mirror promotion
                </h4>
                <span className="mt-[6px] mb-3 flex justify-center items-center  w-fit text-[11px] not-italic font-semibold leading-[100%]">
                  <span className=" w-4 h-4">
                    {closed ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="14"
                        viewBox="0 0 11 14"
                        fill="none"
                      >
                        <path
                          d="M9.83398 6.02148H9.56315V4.39648C9.56315 2.15615 7.74153 0.333984 5.50065 0.333984C3.26032 0.333984 1.43815 2.15615 1.43815 4.39648V6.02148H1.16732C0.569318 6.02148 0.0839844 6.50682 0.0839844 7.10482V12.5215C0.0839844 13.1195 0.569318 13.6048 1.16732 13.6048H9.83398C10.432 13.6048 10.9173 13.1195 10.9173 12.5215V7.10482C10.9173 6.50682 10.432 6.02148 9.83398 6.02148ZM6.04232 9.66311V11.1673C6.04232 11.3163 5.92044 11.4382 5.77148 11.4382H5.22982C5.08032 11.4382 4.95898 11.3163 4.95898 11.1673V9.66311C4.63669 9.47515 4.41732 9.13065 4.41732 8.72982C4.41732 8.13182 4.90265 7.64648 5.50065 7.64648C6.09865 7.64648 6.58398 8.13182 6.58398 8.72982C6.58398 9.12957 6.36515 9.47515 6.04232 9.66311ZM7.93815 6.02148H3.06315V4.39648C3.06315 3.05261 4.15678 1.95898 5.50065 1.95898C6.84398 1.95898 7.93815 3.05261 7.93815 4.39648V6.02148Z"
                          fill="#1F1F1F"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M13.8136 4.10747L13.6042 4.17387L12.4888 4.27324L12.1737 4.77636L11.9451 4.70372L11.0574 3.90337L10.9286 3.48715L10.7562 3.0433L10.1982 2.54285L9.54002 2.41406L9.52487 2.71531L10.1697 3.34454L10.4852 3.7162L10.1305 3.90158L9.84171 3.81647L9.409 3.63599L9.42371 3.28706L8.85597 3.05355L8.66747 3.87396L8.09528 4.00363L8.15187 4.4613L8.89742 4.60479L9.0262 3.87351L9.64162 3.96442L9.92772 4.13198H10.3867L10.7009 4.76121L11.5338 5.60613L11.4727 5.93456L10.8012 5.849L9.64073 6.43501L8.80517 7.43724L8.69644 7.88109H8.39653L7.8377 7.62351L7.29492 7.88109L7.42995 8.45372L7.66613 8.18144L8.08146 8.16852L8.0525 8.68278L8.39653 8.78349L8.74011 9.16941L9.30116 9.01166L9.94198 9.11282L10.6862 9.3129L11.0578 9.35658L11.688 10.0718L12.9041 10.7871L12.1176 12.2897L11.2873 12.6756L10.9723 13.5344L9.77086 14.3365L9.64296 14.7991C12.7143 14.0593 14.999 11.3004 14.999 8.00185C14.9981 6.56112 14.5614 5.22021 13.8136 4.10747Z"
                          fill="#1F1F1F"
                        />
                        <path
                          d="M8.80543 11.6611L8.29562 10.7159L8.76354 9.74084L8.29562 9.60091L7.77022 9.07328L6.60623 8.81214L6.21987 8.00376V8.48371H6.04964L5.04652 7.12364V6.00643L4.31122 4.8108L3.14366 5.01891H2.35712L1.9614 4.75955L2.4663 4.35938L1.96274 4.47569C1.35579 5.51223 1.00195 6.71499 1.00195 8.00331C1.00195 11.8683 4.1352 15.0029 8.00062 15.0029C8.2983 15.0029 8.59019 14.9766 8.87896 14.9418L8.80587 14.0938C8.80587 14.0938 9.12718 12.8344 9.12718 12.7916C9.12673 12.7484 8.80543 11.6611 8.80543 11.6611Z"
                          fill="#1F1F1F"
                        />
                        <path
                          d="M3.60156 3.25713L4.84488 3.08378L5.41796 2.76961L6.06279 2.95544L7.0931 2.8984L7.44604 2.34358L7.96074 2.42825L9.21074 2.31105L9.55522 1.93137L10.041 1.60695L10.7281 1.71034L10.9786 1.67246C10.0735 1.24643 9.06636 1 7.99907 1C5.82661 1 3.88409 1.9902 2.60156 3.54501H2.60513L3.60156 3.25713ZM8.29497 1.69608L9.00976 1.30258L9.46876 1.56774L8.80433 2.07353L8.16974 2.13725L7.88409 1.95187L8.29497 1.69608ZM6.17777 1.75357L6.49327 1.88503L6.90637 1.75357L7.13142 2.14349L6.17777 2.39394L5.71921 2.12567C5.71921 2.12567 6.16752 1.8369 6.17777 1.75357Z"
                          fill="#1F1F1F"
                        />
                      </svg>
                    )}
                  </span>
                  <p className="text-xs ml-1  not-italic font-semibold leading-[130%]">
                    {closed ? "Closed" : " Open"}{" "}
                  </p>
                </span>
              </div>

              <p className="text-sm not-italic font-normal leading-[140%] mt-2 ">
                Black mirror is an online television show, available on the
                streaming platform, Netflix, and it’s new episode is coming up
                and with the help of prominent social media celebrities, Netflix
                aim to reach new audiences and also grab the attention of
                previous one’s...
              </p>
            </div>

            <div className="mt-6 ">
              <span className="flex text-sm not-italic font-semibold leading-[130%] text-[#808080]">
                Label:
                <p className="text-primary  font-semibold leading-[140%] ml-1">
                  3-4 days
                </p>
              </span>
              <div className="flex justify-start items-center mt-3 ">
                <p className="text-xs not-italic font-semibold leading-[130%]  text-[#808080]">
                  Mediums:
                </p>
                {selectedRowsData.length > 0 && (
                  <div className="flex h-[25px] justify-start items-center ">
                    {selectedRowsData.map((medium: any, index: any) => (
                      <span
                        className="ml-1 flex justify-start bg-[#FFE58A] w-fit text-[#6C5606] py-1 px-3 rounded-[30px] text-sm text-[13px] not-italic font-medium leading-[130%] "
                        key={index}
                      >
                        {medium.label}
                        <p className="ml-1">{`  (${medium.number})`}</p>
                      </span>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex justify-start items-center mt-3">
                <p className="text-xs not-italic font-semibold leading-[130%]  text-[#808080]">
                  Niche:
                </p>
                {selectedNichisData.length > 0 && (
                  <div className="flex h-[25px] justify-content items-center">
                    {selectedNichisData.map((medium: any, index: any) => (
                      <span
                        className="ml-1 bg-[#FFE58A] w-fit text-[#6C5606] py-1 px-3 rounded-[30px] text-sm text-[13px] not-italic font-medium leading-[130%] "
                        key={index}
                      >
                        {medium}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="absolute top-[33px] right-[32px]">
              <svg
                width="43"
                height="69"
                viewBox="0 0 43 69"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.05">
                  <path
                    d="M24.7191 10.4321L3.87558 0.1306C3.72964 0.0584716 3.55286 0.118308 3.48073 0.264249L0.129719 7.0445C0.0575902 7.19044 0.117427 7.36722 0.263368 7.43935L21.1068 17.7408C21.2528 17.813 21.4296 17.7531 21.5017 17.6072L24.8527 10.8269C24.9248 10.681 24.865 10.5042 24.7191 10.4321Z"
                    fill="#1F1F1F"
                  />
                  <path
                    d="M36.824 30.2301C40.2347 30.2301 42.9996 27.4652 42.9996 24.0545C42.9996 20.6438 40.2347 17.8789 36.824 17.8789C33.4133 17.8789 30.6484 20.6438 30.6484 24.0545C30.6484 27.4652 33.4133 30.2301 36.824 30.2301Z"
                    fill="#1F1F1F"
                  />
                  <path
                    d="M20.7631 20.1101L9.31778 20.0902C9.21455 20.09 9.13071 20.1735 9.13053 20.2768L9.11706 27.9981C9.11688 28.1013 9.20042 28.1851 9.30365 28.1853L20.749 28.2053C20.8522 28.2055 20.9361 28.1219 20.9362 28.0187L20.9497 20.2974C20.9499 20.1942 20.8664 20.1103 20.7631 20.1101Z"
                    fill="#1F1F1F"
                  />
                  <path
                    d="M30.37 41.3094C26.6818 40.4395 23.6983 38.5127 21.4193 35.5292C21.2539 35.3135 21.2827 35.0043 21.4912 34.8246L26.5237 30.4967C26.7034 30.3457 26.8616 30.3601 27.0126 30.5326C30.6216 34.6952 34.7914 35.2847 40.0108 34.4939C40.3056 34.4436 40.4278 34.573 40.3631 34.8677L33.4614 67.8954C33.411 68.147 33.2529 68.2764 32.994 68.2764H25.5316C25.2009 68.2764 25.0714 68.1111 25.1361 67.7875L30.7294 41.8773C30.7869 41.6257 30.6216 41.3669 30.37 41.3094Z"
                    fill="#1F1F1F"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>

        <div className="flex mt-6 w-full justify-end mb-10">
          <button
            onClick={() => setCurrent(current - 1)}
            className=" text-[13px] mr-5     not-italic font-semibold leading-[130%] py-3 px-6 rounded-[6px] border flex justify-center items-center border-primary text-primary hover:bg-black_button_hover hover:text-primary hover:border-transparent  "
          >
            Go back to edit
          </button>
          <button
            className={` 
                 bg-primary  hover:bg-black_button_hover hover:text-primary hover:border-transparent
               text-[13px] not-italic font-semibold leading-[130%] py-3 px-6 rounded-[6px] border flex justify-center items-center border-primary text-white  `}
            // onClick={next}
          >
            Send and publish
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreviewPublish;
