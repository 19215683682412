import React from "react";
import Logo from "../../assets/svg/logo.svg";
import BuisnessIcon from "../../assets/svg/buisness.svg";
import CampaignIcon from "../../assets/svg/campaign.svg";
import ChartIcon from "../../assets/svg/chart.svg";
import ProgrammingIcon from "../../assets/svg/programming.svg";
import HomeIcon from "../../assets/svg/home.svg";

const SideNav = () => {
  const links = [
    {
      icon: HomeIcon,
      text: "Home ",
      url: "#",
      active: true,
    },
    {
      icon: CampaignIcon,
      text: "Campaign",
      url: "#",
    },
    {
      icon: ProgrammingIcon,
      text: "Programming",
      url: "#",
    },
    {
      icon: ChartIcon,
      text: "Chart",
      url: "#",
    },
    {
      icon: BuisnessIcon,
      text: "Buisness",
      url: "#",
    },
  ];

  return (
    <div className="h-screen flex bg-gray-200 w-[114px] border-none ">
      <div className="flex flex-col items-center bg-white text-gray-700 h-full w-full p-[32px]">
        <div className="  flex justify-center items-center bg-gradient-yellow w-[50px] h-[50px] rounded-[10px]">
          <span className="cursor-pointer">
            <a className=" cursor-pointer" href="">
              <img
                className="h-full w-full cursor-pointer"
                src={Logo}
                alt="svelte logo"
              />
            </a>
          </span>
        </div>

        <ul className="mt-[64px]">
          {links.map((link, index) => (
            <li
              className={`${
                link.active ? "bg-primary" : "bg-white"
              }  hover:bg-black_button_hover hover:text-primary   00 w-[50px] h-[50px]  rounded-[10px] mb-[24px]`}
              key={index}
            >
              <a
                href={link.url}
                className=" flex justify-center items-center w-full h-full  focus:text-orange-500"
              >
                <img className="h-6 w-6" src={link.icon} alt="svelte logo" />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideNav;
