import React from "react";
import { Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import "./PickCreatorsTable.scss";

interface UserData {
  profileImage: string;
  userName: string;
  userId: string;
}
interface CreatorData {
  key: string;
  name: UserData;
  likes: number;
  engagements: number;
  engagementRate: number;
  ctr: number;
  checked: boolean;
}
type TablePaginationPosition =
  | "topLeft"
  | "topCenter"
  | "topRight"
  | "bottomLeft"
  | "bottomCenter"
  | "bottomRight";

interface PaginationType {
  position: TablePaginationPosition[];
}

interface PickCreatorsTableProps {
  tableData: CreatorData[];
  tableColumns: ColumnsType<CreatorData>;
  pagination?: false | PaginationType;
}

const PickCreatorsTable: React.FC<PickCreatorsTableProps> = ({
  tableData,
  tableColumns,
  pagination,
}) => {
  const customRowRenderer = (record: CreatorData) => {
    return record.checked ? "table-row-checked" : "";
  };

  const onChange: TableProps<CreatorData>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    // console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <Table
      className="pick-creator-table"
      pagination={pagination}
      columns={tableColumns}
      dataSource={tableData}
      onChange={onChange}
      rowClassName={customRowRenderer}
    />
  );
};

export default PickCreatorsTable;
