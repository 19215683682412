import React, { useState } from 'react';
import DashboardHead from './DashboardHead';
import DashboardCampaignCard from './DashboardCampaignCard';
import Leaderboard from './Leaderboard';
import ActiveCampaigns from './ActiveCampaigns';
import ContentSuggestions from './ContentSuggestions';
import IndustryInsights from './IndustryInsights';
import EngagingCreators from './EngagingCreators';
import TrendingArticles from './TrendingArticles';
import BrandSentiment from './BrandSentiment';
import BookDemo from './BookDemo';

const DashboardContents = () => {
	const [showSub, setShowSub] = useState(false);

	return (
		<div className=" flex flex-col bg-white w-full  border-none py-[37px] h-screen overflow-y-scroll ">
			<DashboardHead setShowSub={setShowSub} showSub={showSub} />
			<div className="flex ">
				<div className="flex flex-col mt-[36px]  min-w-[845px] ">
					<div className="flex justify-between ">
						<DashboardCampaignCard setShowSub={setShowSub} showSub={showSub} />
						<Leaderboard setShowSub={setShowSub} showSub={showSub} />
					</div>
					<ActiveCampaigns setShowSub={setShowSub} showSub={showSub} />
					<BrandSentiment setShowSub={setShowSub} showSub={showSub} />
					<ContentSuggestions setShowSub={setShowSub} showSub={showSub} />
				</div>
				<div className="flex flex-col w-full ml-[51px] mr-[32px] min-w-[390px]  max-w-[390px]">
					{showSub && <BookDemo />}
					<IndustryInsights setShowSub={setShowSub} showSub={showSub} />
					<EngagingCreators setShowSub={setShowSub} showSub={showSub} />
					<TrendingArticles setShowSub={setShowSub} showSub={showSub} />
				</div>
			</div>
		</div>
	);
};

export default DashboardContents;
