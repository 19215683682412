import { Modal } from "antd";
import React, { useState, ReactNode } from "react";
import "./Modal.scss";
interface IModalPopupProps {
  title?: string;
  children: ReactNode;
  footer?: ReactNode;
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  width?: string | number;
}

const ModalPopup: React.FC<IModalPopupProps> = ({
  title,
  children,
  isModalOpen,
  handleOk,
  handleCancel,
  footer,
  width,
}) => {
  return (
    <Modal
      className="get-suggestion-modal-popup"
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footer}
      width={width}
    >
      {children}
    </Modal>
  );
};

export default ModalPopup;
