import { Select } from "antd";
import React, { ReactNode, CSSProperties } from "react";
import dropdownIcon from "../../assets/svg/downArrowBlack.svg";
import "./CustomSelect.scss";

interface selectOption {
    label: string
    value: string
}

interface ICustomSelectProps {
    className?: string;
    placeholder?: ReactNode;
    style?: CSSProperties;
    suffixIcon?: ReactNode;
    options: selectOption[]
}

const CustomSelect: React.FunctionComponent<ICustomSelectProps> = ({
    className,
    placeholder,
    style,
    suffixIcon = <img src={dropdownIcon} alt="campaignAdd" />,
    options
}) => {
    return (
        <Select
            className={className}
            placeholder={placeholder}
            style={style}
            suffixIcon={suffixIcon}
            options={options}
        />
    );
};

export default CustomSelect;
