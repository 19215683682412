import React, { ReactNode, CSSProperties } from "react";
import { Drawer } from "antd";

type DrawerPlacement = "top" | "right" | "bottom" | "left";

interface ICustomDrawerProps {
  title?: string;
  children: ReactNode;
  size?: "default" | "large";
  width?: string | number;
  bodyStyle: CSSProperties;
  placement?: DrawerPlacement;
  onClose: () => void;
  open: boolean;
  closeIcon?: boolean | ReactNode;
  footer?: ReactNode;
}

const CustomDrawer: React.FunctionComponent<ICustomDrawerProps> = ({
  title,
  children,
  bodyStyle,
  size = "large",
  width,
  placement = "right",
  onClose,
  open,
  closeIcon = false,
  footer,
}) => {
  return (
    <Drawer
      title={title}
      className="custom-drawer"
      bodyStyle={bodyStyle}
      size={size}
      width={width}
      placement={placement}
      onClose={onClose}
      open={open}
      closeIcon={closeIcon}
      footer={footer}
    >
      {children}
    </Drawer>
  );
};

export default CustomDrawer;
