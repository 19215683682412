import React, { useState } from "react";
import logo from "../assets/png/logo.png";
import HeroSection from "../components/login/HeroSection";
import LoginForm from "../components/login/LoginForm";
const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className="bg-white">
      <div className="flex min-h-screen">
        <div className="flex flex-row w-full">
          <div className="flex flex-1 flex-col items-center justify-center px-10 relative">
            <div className=" flex justify-start items-start w-full mt-[44px]">
              <img src={logo} alt="Logo" className="w-28 h-8" />
            </div>
            <LoginForm />
          </div>
          <HeroSection />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
