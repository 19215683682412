import React, { createContext, useContext, useState } from "react";

interface GlobalStateContextProps {
  selectedRowsData: any[];
  setSelectedRowsData: React.Dispatch<React.SetStateAction<any[]>>;
  selectedNichisData: any[];
  setSelectedNichiData: React.Dispatch<React.SetStateAction<any[]>>;
}

const GlobalStateContext = createContext<GlobalStateContextProps | undefined>(
  undefined
);

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }
  return context;
};

interface GlobalStateProviderProps {
  children: React.ReactNode;
}

const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({
  children,
}) => {
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([]);
  const [selectedNichisData, setSelectedNichiData] = useState<any[]>([]);

  const contextValue: GlobalStateContextProps = {
    selectedRowsData,
    setSelectedRowsData,
    selectedNichisData,
    setSelectedNichiData,
  };

  return (
    <GlobalStateContext.Provider value={contextValue}>
      {children}
    </GlobalStateContext.Provider>
  );
};
export { GlobalStateProvider };
