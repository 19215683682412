import React, { useState, ReactNode } from "react";
import "./FloatLabel.css";

interface FloatLabelProps {
  label: string;
  value: any;
  children: any;
}

const FloatLabel: React.FC<FloatLabelProps> = ({ label, value, children }) => {
  const [focus, setFocus] = useState(false);

  const labelClass =
    focus || (value && value.length !== 0) ? "label label-float" : "label";

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass}>{label}</label>
    </div>
  );
};

export default FloatLabel;
