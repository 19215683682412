import React, { useEffect, useState } from "react";
import CustomDrawer from "../../Drawer/CustomDrawer";
import { CloseIcon } from "../../../Icons";
import { Button, Checkbox } from "antd";
import { Col, Row } from "antd";
import "../../../styles/Checkbox.css";
import CheckboxGroup from "../CheckboxGroup/CheckboxGroup";
import CustomSlider from "../../RangeSlider/CustomSlider";

interface IFilterDrawerProps {
  onClose: () => void;
  openDrawer: boolean;
}

interface Option {
  label: string;
  value: string;
  disabled?: boolean;
}

const FilterDrawer: React.FC<IFilterDrawerProps> = ({
  onClose,
  openDrawer,
}) => {
  const [sliderValue, setSliderValue] = useState<number | [number, number]>([
    0, 50000,
  ]);

  const checkboxGroup1: Option[] = [
    {
      label: "List 1",
      value: "List 1",
    },
    {
      label: "List 2",
      value: "List 2",
    },
    {
      label: "List 3",
      value: "List 3",
    },
    {
      label: "List 4",
      value: "List 4",
    },
    {
      label: "List 5",
      value: "List 5",
    },
    {
      label: "List 6",
      value: "List 6",
    },
    {
      label: "List 7",
      value: "List 7",
    },
    {
      label: "List 8",
      value: "List 8",
    },
    {
      label: "List 9",
      value: "List 9",
    },
    {
      label: "List 10",
      value: "List 10",
    },
    {
      label: "List 11",
      value: "List 11",
    },
    {
      label: "List 12",
      value: "List 12",
    },
    {
      label: "List 13",
      value: "List 13",
    },
    {
      label: "List 14",
      value: "List 14",
    },
    {
      label: "List 15",
      value: "List 15",
    },
    {
      label: "List 16",
      value: "List 16",
    },
    {
      label: "List 17",
      value: "List 17",
    },
    {
      label: "List 18",
      value: "List 18",
    },
    {
      label: "List 19",
      value: "List 19",
    },
    {
      label: "List 20",
      value: "List 20",
    },
    {
      label: "List 21",
      value: "List 21",
    },
    {
      label: "List 22",
      value: "List 22",
    },
    {
      label: "List 23",
      value: "List 23",
    },
    {
      label: "List 24",
      value: "List 24",
    },
    {
      label: "List 25",
      value: "List 25",
    },
    {
      label: "List 26",
      value: "List 26",
    },
  ];

  const checkboxGroup2: Option[] = [
    {
      label: "Celebrity",
      value: "Celebrity",
    },
    {
      label: "Nano",
      value: "Nano",
    },
    {
      label: "Macro",
      value: "Macro",
    },
    {
      label: "Micro",
      value: "Micro",
    },
  ];

  const checkboxGroup3: Option[] = [
    {
      label: "Mumbai",
      value: "Mumbai",
    },
    {
      label: "Kolkata",
      value: "Kolkata",
    },
    {
      label: "Chennai",
      value: "Chennai",
    },
    {
      label: "Bengaluru",
      value: "Bengaluru",
    },
    {
      label: "Jaipur",
      value: "Jaipur",
    },
    {
      label: "Agra",
      value: "Agra",
    },
  ];

  const checkboxGroup4: Option[] = [
    {
      label: "English",
      value: "English",
    },
    {
      label: "Hindi",
      value: "Hindi",
    },
    {
      label: "Tamil",
      value: "Tamil",
    },
    {
      label: "Telugu",
      value: "Telugu",
    },
    {
      label: "Gujarati",
      value: "Gujarati",
    },
    {
      label: "Marathi",
      value: "Marathi",
    },
  ];
  const checkboxGroup5: Option[] = [
    {
      label: "3 - 18",
      value: "3 - 18",
    },
    {
      label: "19 - 35",
      value: "19 - 35",
    },
    {
      label: "35 - 55",
      value: "35 - 55",
    },
    {
      label: "56+",
      value: "56+",
    },
  ];

  const checkboxGroup6: Option[] = [
    {
      label: "Fashion/ beauty",
      value: "Fashion/ beauty",
    },
    {
      label: "Software products",
      value: "Software products",
    },
    {
      label: "Education",
      value: "Education",
    },
    {
      label: "Bengaluru",
      value: "Bengaluru",
    },
    {
      label: "Jaipur",
      value: "Jaipur",
    },
    {
      label: "Agra",
      value: "Agra",
    },
  ];

  return (
    <>
      <CustomDrawer
        width="616px"
        bodyStyle={{
          padding: "40px",
          color: "#1F1F1F",
          fontFeatureSettings: "'liga' off, 'rclt' off",
          fontFamily: "Montserrat",
        }}
        onClose={onClose}
        open={openDrawer}
        footer={
          <div className="flex">
            <button className="bg-primary rounded-md px-6 py-3 hover:bg-black_button_hover group transition-[background] duration-300 ease-[ease]">
              <span className="text-white text-[13px] font-semibold leading-[130%] group-hover:text-primary">
                Apply
              </span>
            </button>
            <button className="bg-white rounded-md px-6 py-3 hover:bg-black_button_hover group duration-300 ease-[ease]">
              <span className="text-primary text-[13px] font-semibold leading-[130%] group-hover:text-primary">
                Cancel
              </span>
            </button>
          </div>
        }
      >
        <div>
          <div className="flex items-center justify-between mb-8">
            <h1 className="text-primary text-2xl font-semibold leading-[120%] tracking-[-1px]">
              Filters
            </h1>
            <button onClick={onClose} className="group inline-block">
              <CloseIcon className="fill-current transition duration-300 group-hover:fill-[blue]" />
            </button>
          </div>
          <CheckboxGroup title="Available platforms" options={checkboxGroup1} />
          <CheckboxGroup
            bodyClassName="mt-5"
            title="Type"
            options={checkboxGroup2}
            maxItems={6}
          />
          <CheckboxGroup
            bodyClassName="mt-5"
            title="Cities"
            options={checkboxGroup3}
            maxItems={6}
          />
          <CheckboxGroup
            bodyClassName="mt-5"
            title="Languages"
            options={checkboxGroup4}
            maxItems={6}
          />
          <CheckboxGroup
            bodyClassName="mt-5"
            title="Age group"
            options={checkboxGroup5}
            maxItems={6}
          />
          <CustomSlider
            trackStyle={[{ backgroundColor: "#FFC700" }]}
            title="Budget"
            bodyClassName="mt-5"
            range={true}
            defaultValue={[0, 50000]}
            min={0}
            max={50000}
            setSliderValue={setSliderValue}
            sliderValue={sliderValue}
          />
          <CheckboxGroup
            bodyClassName="mt-5"
            title="Niche"
            options={checkboxGroup6}
            maxItems={6}
          />
        </div>
      </CustomDrawer>
    </>
  );
};

export default FilterDrawer;
