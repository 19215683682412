import React, { useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import actionDots from "../../assets/svg/actionDots.svg";
import cardAvatar from "../../assets/png/cardAvatar.png";
import cardAvatar1 from "../../assets/png/ac1.png";
import cardAvatar2 from "../../assets/png/ac2.png";
import cardAvatar3 from "../../assets/png/ac3.png";
import cardAvatar4 from "../../assets/png/ac4.png";
import cardAvatar5 from "../../assets/png/ac5.png";
import SubscriptionLock from "./SubscriptionLock";
import dummyActive from "../../assets/svg/dummyActive.svg";

interface CampaignItem {
  title: string;
  budget: string;
  influencers: number;
  status: string;
  Image: string[];
}

interface ActiveCampaignsProps {
  showSub: boolean;
  setShowSub: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActiveCampaigns: React.FC<ActiveCampaignsProps> = ({
  showSub,
  setShowSub,
}) => {
  const owlRef = useRef<any>(null);

  const campaignData: CampaignItem[] = [
    {
      title: "Black Mirror promotion",
      budget: "3,00,000",
      influencers: 5,
      status: "Complete",
      Image: [cardAvatar, cardAvatar1, cardAvatar2],
    },
    {
      title: "Chainsaw man",
      budget: "3,00,000",
      influencers: 7,
      status: "Pending",
      Image: [cardAvatar4, cardAvatar5, cardAvatar3],
    },
    {
      title: "Black Mirror promotion",
      budget: "3,00,000",
      influencers: 7,
      status: "Draft",
      Image: [cardAvatar, cardAvatar1, cardAvatar2],
    },
    {
      title: "Black Mirror promotion",
      budget: "3,00,000",
      influencers: 7,
      status: "Cost Approval",
      Image: [cardAvatar3, cardAvatar4, cardAvatar5],
    },
    {
      title: "Black Mirror promotion",
      budget: "3,00,000",
      influencers: 7,
      status: "Deliverables",
      Image: [cardAvatar4],
    },
    {
      title: "Black Mirror promotion",
      budget: "3,00,000",
      influencers: 7,
      status: "Go live  ",
      Image: [cardAvatar5],
    },
  ];

  const handlePrevClick = () => {
    if (owlRef.current) {
      owlRef.current.prev();
    }
  };

  const handleNextClick = () => {
    if (owlRef.current) {
      owlRef.current.next();
    }
  };

  return (
    <>
      {!showSub ? (
        <div className="bg-white mt-3 relative rounded-[16px] px-[24px] py-[25px] border border-solid border-primary">
          <div className="flex w-full items-center justify-between">
            <h6 className=" font-semibold text-[19px]">Active campaigns</h6>
            <div className="flex">
              <button
                onClick={handlePrevClick}
                type="button"
                className="flex z-30 justify-center items-center cursor-pointer group focus:outline-none"
                data-carousel-prev
              >
                <span className="inline-flex border-[1px] border-solid border-[#A4A4A4] hover:border-transparent justify-center bg-white hover:bg-primary items-center w-[24px] h-[24px] rounded-full group-focus:outline-none">
                  <svg
                    className="icon-svg transition-fill text-[#A4A4A4] group-hover:text-white"
                    width="12"
                    height="10"
                    viewBox="0 0 12 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5838 4.99827L10.2581 4.99827"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.08228 1.51596L1.58366 4.99954L5.08228 8.4837"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </button>
              <button
                onClick={handleNextClick}
                type="button"
                className="flex left-0 z-30 justify-center items-center ml-2 cursor-pointer group focus:outline-none"
                data-carousel-next
              >
                <span className="inline-flex justify-center bg-white border-[1px] border-solid border-[#A4A4A4] hover:border-transparent hover:bg-primary items-center w-[24px] h-[24px] rounded-full group-focus:outline-none">
                  <svg
                    className="icon-svg transition-fill text-[#A4A4A4] group-hover:text-white"
                    width="12"
                    height="10"
                    viewBox="0 0 12 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.4162 4.99827L1.74194 4.99827"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.91772 1.51596L10.4163 4.99954L6.91772 8.4837"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <div className="flex justify-between mt-[20px] ">
            <OwlCarousel
              className="owl-theme"
              margin={12}
              startPosition={0}
              dots={false}
              ref={owlRef}
              responsive={{
                0: { items: 1 },
                1440: { items: 2 },
                1800: { items: 3 },
              }}
            >
              {campaignData?.map((campaignItem, index) => (
                <div className="flex border flex-col border-solid border-[#EFEFEF] rounded-[12px] p-[20px] shadow-none hover:shadow-[0px_6px_12px_0px_rgba(0,0,0,0.06)] hover:border-[#E2E2E2]">
                  <div className="justify-between flex">
                    <h4 className="text-base font-semibold leading-[19px] tracking-[-1px]">
                      {campaignItem.title}
                    </h4>
                    <div className="justify-center items-center flex">
                      <span
                        className={`${
                          campaignItem.status === "Complete"
                            ? " bg-[#DBE9CF] border-[#D0E3BF]"
                            : campaignItem.status === "Draft"
                            ? " bg-[#F9EECE] border-[#EBDFBA]"
                            : campaignItem.status === "Cost Approval"
                            ? " bg-[#F4D1C0] border-[#E7BFAC]"
                            : campaignItem.status === "Deliverables"
                            ? " bg-[#C9DAF0] border-[#B6CAE3]"
                            : campaignItem.status === "Go live"
                            ? " bg-[#F5E1F8] border-[#EAD0EE]"
                            : campaignItem.status === "Pending"
                            ? " bg-[#F9EECE] border-[#EBDFBA]"
                            : "bg-[#F9EECE] border-[#EBDFBA]"
                        }   flex justify-center text-primary items-center h-[20px] border-solid border rounded-[12px]`}
                      >
                        <p
                          className={`text-[11px] font-semibold leading-[11px] px-2 py-[5px] `}
                        >
                          {campaignItem.status}
                        </p>
                      </span>
                      <span className="w-5 h-5 flex justify-center items-center ml-2">
                        <img
                          className="h-[15px] w-[15px] cursor-pointer"
                          src={actionDots}
                          alt="campaignAdd"
                        />
                      </span>
                    </div>
                  </div>
                  <span className="text-[#808080] mt-2 flex text-xs font-semibold leading-4">
                    Budget:
                    <p className="text-primary ml-1">
                      {" "}
                      ₹ {campaignItem.budget}
                    </p>
                  </span>
                  <p className="text-[#808080] mt-[24px] flex text-xs font-semibold leading-4">
                    Influencers
                  </p>
                  <div className="flex  w-full justify-end flex-row-reverse md:mt-2 lg:mt-0">
                    <span className="z-0 text-[14px] font-semibold ml-px inline-flex w-[48px] h-[48px] cursor-pointer  items-center justify-center rounded-full border-2 border-white bg-primary text-xs text-white ">
                      +{campaignItem.influencers}
                    </span>
                    <span className="z-14 -mr-3 w-[48px] h-[48px]  rounded-full border-2 border-white">
                      <img
                        className="h-full w-full rounded-full object-cover"
                        src={campaignItem.Image[2]}
                        alt=""
                      />
                    </span>
                    <span className="z-15 -mr-3 w-[48px] h-[48px]  rounded-full border-2 border-white">
                      <img
                        className="h-full w-full rounded-full object-cover"
                        src={campaignItem.Image[1]}
                        alt=""
                      />
                    </span>
                    <span className="z-16 -mr-3 w-[48px] h-[48px]  rounded-full border-2 border-white">
                      <img
                        className="h-full w-full rounded-full object-cover"
                        src={campaignItem.Image[0]}
                        alt=""
                      />
                    </span>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      ) : (
        <SubscriptionLock
          containerClass="relative max-w-full mt-2 h-[290px] max-w-[300px]  border border-solid border-primary rounded-[16px]"
          imgSrc={dummyActive}
          opacity={1}
          heading="Active campaigns"
        />
      )}
    </>
  );
};

export default ActiveCampaigns;
