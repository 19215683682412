import React from "react";
import cardBg from "../../assets/svg/insightCardbg.svg";
import SubscriptionLock from "./SubscriptionLock";
import dummyInsigts from "../../assets/svg/dummyInsigts.svg";
interface IndustryInsightsProps {
  showSub: boolean;
  setShowSub: React.Dispatch<React.SetStateAction<boolean>>;
}

const IndustryInsights: React.FC<IndustryInsightsProps> = ({
  showSub,
  setShowSub,
}) => {
  const cardData = [
    { average: "Avg CPV", platform: "All platforms", value: "₹100" },
    { average: "Avg CPR", platform: "Social media", value: "₹150" },
    { average: "Avg CPE ", platform: "Video ads", value: "₹80" },
  ];
  return (
    <>
      {!showSub ? (
        <div className=" w-full mt-[49px]">
          <div className="flex justify-between ">
            <h6 className=" text-[19px] font-semibold  text-[19px]">
              Industry insights
            </h6>
            <button className=" hover:text-link_hover text-[13px] not-italic font-semibold leading-[130%] underline cursor-pointer underline-offset-2 text-primary">
              Explore more
            </button>
          </div>
          <div className="flex justify-between mt-5  gap-3 w-[387px]">
            {cardData.map((data, index) => (
              <div
                key={index}
                className="w-[121px] h-28 bg-[#FFE58A] rounded-2xl p-4  relative"
              >
                <img src={cardBg} className="absolute right-0 bottom-0" />
                <p className="text-[14px] font-[600] leading-[120%]">
                  {data.average}
                </p>
                <p className="text-[#666666] mt-1 text-[12px] not-italic font-medium leading-[130%]">
                  {data.platform}
                </p>
                <p className="text-[16px] not-italic mt-6 font-semibold leading-[120%] tracking-[-1px]">
                  {data.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <SubscriptionLock
          containerClass="relative max-w-full mt-[64px] h-[203px] max-w-[300px]  border border-solid border-primary rounded-[16px]"
          imgSrc={dummyInsigts}
          opacity={1}
          heading="Industry insights"
        />
      )}
    </>
  );
};

export default IndustryInsights;
