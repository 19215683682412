// index.js
import React from "react";
import ReactDOM from "react-dom";
import AppRouter from "./AppRouter";
import "tailwindcss/tailwind.css";
import { GlobalStateProvider } from "../src/context/GlobalStateContext";
ReactDOM.render(
  <React.StrictMode>
    <GlobalStateProvider>
      <AppRouter />
    </GlobalStateProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
