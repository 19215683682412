import React from "react";
import { Button } from "antd";
import { Console, log } from "console";

interface CreateCampaignFooterProps {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  isSelected: boolean;
}

const CreateCampaignFooter: React.FC<CreateCampaignFooterProps> = ({
  current,
  setCurrent,
  isSelected,
}) => {
  const next = () => {
    setCurrent(current + 1);
  };

  return (
    <div className="w-full px-10 py-4 bg-white border-t border-neutral-200 justify-start items-start gap-[1015px] inline-flex">
      <div className="w-full flex justify-between">
        <button className=" text-[13px] not-italic font-semibold leading-[130%] py-3 px-6 rounded-[6px] border flex justify-center items-center border-primary text-primary hover:bg-black_button_hover hover:text-primary hover:border-transparent  ">
          Save as draft
        </button>
        <div className="flex">
          {current === 1 && (
            <button
              onClick={next}
              className=" text-[13px] mr-5     not-italic font-semibold leading-[130%] py-3 px-6 rounded-[6px] border flex justify-center items-center border-primary text-primary hover:bg-black_button_hover hover:text-primary hover:border-transparent  "
            >
              Skip now
            </button>
          )}
          <button
            disabled={!isSelected && current === 1}
            className={` ${
              !isSelected && current === 1
                ? " bg-gray-400 border-transparent cursor-not-allowed"
                : "bg-primary  hover:bg-black_button_hover hover:text-primary hover:border-transparent"
            }  text-[13px] not-italic font-semibold leading-[130%] py-3 px-6 rounded-[6px] border flex justify-center items-center border-primary text-white  `}
            onClick={next}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateCampaignFooter;
