import React from "react";
import cardAvatar from "../../assets/png/cardAvatar.png";
import cardBanner from "../../assets/png/articleBanner.png";
import cardBanner1 from "../../assets/png/articleBanner1.png";
import cardBanner2 from "../../assets/png/articleBanner2.png";
import SubscriptionLock from "./SubscriptionLock";
import dummyart from "../../assets/svg/dummyart.svg";
interface TrendingArticlesProps {
  showSub: boolean;
  setShowSub: React.Dispatch<React.SetStateAction<boolean>>;
}

const TrendingArticles: React.FC<TrendingArticlesProps> = ({
  showSub,
  setShowSub,
}) => {
  const creatorsData = [
    {
      image: cardBanner1,
      title: "How Myntra earned its first billion, Myntra's Path to Success",
      author: "Samuel",
      readTime: "15 min read",
    },
    {
      image: cardBanner2,
      title: "How Myntra earned its first billion, Myntra's Path to Success",
      author: "Samuel",
      readTime: "15 min read",
    },
    {
      image: cardBanner,
      title: "How Myntra earned its first billion, Myntra's Path to Success",
      author: "Samuel",
      readTime: "15 min read",
    },
  ];
  return (
    <>
      {!showSub ? (
        <div className="h-[155px] w-full mt-10">
          <div className="flex justify-between ">
            <h6 className=" text-[19px] font-semibold  text-[19px]">
              Trending articles
            </h6>
            <button className="hover:text-link_hover  text-[13px] not-italic font-semibold leading-[130%] cursor-pointer underline underline-offset-2 text-primary">
              View all
            </button>
          </div>
          <div className=" w-full space-y-[12px] mt-5 ">
            {creatorsData?.map((creatorsData, index) => (
              <div className="flex justify-start mt-5 items-center text-primary w-full h-full cursor-pointer">
                <img
                  className=" w-100 h-100 object-cover rounded-[10px]"
                  src={creatorsData.image}
                  alt=""
                />
                <div className="flex flex-col ml-3">
                  <h6 className="text-sm not-italic font-semibold cursor-pointer leading-[120%] line-clamp-2">
                    {creatorsData.title}
                  </h6>
                  <div className="flex items-center mt-[10px] text-[#808080]">
                    <img
                      className="rounded-full  w-21 h-20 rounded-full "
                      src={cardAvatar}
                      alt=""
                    />
                    <p className="text-xs not-italic cursor-pointer ml-1 leading-[130%]">
                      By {creatorsData.author}
                    </p>
                    <span className="w-1 h-1 bg-[#A4A4A4] rounded-full mx-2" />
                    <p className=" text-xs not-italic cursor-pointer leading-[130%]">
                      {creatorsData.readTime}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <SubscriptionLock
          containerClass="relative max-w-full mt-[24px] h-[415px] max-w-[300px]  border border-solid border-primary rounded-[16px]"
          imgSrc={dummyart}
          opacity={1}
          heading="Trending articles"
        />
      )}
    </>
  );
};

export default TrendingArticles;
