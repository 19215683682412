import React from "react";
import cardAvatar from "../../assets/png/cre1.png";
import cardAvatar1 from "../../assets/png/cre2.png";
import cardAvatar2 from "../../assets/png/cre3.png";
import Instagram from "../../assets/svg/instagram.svg";
import Youtube from "../../assets/svg/youtube.svg";
import SubscriptionLock from "./SubscriptionLock";
import dummyeng from "../../assets/svg/dummyeng.svg";

interface EngagingCreatorsProps {
  showSub: boolean;
  setShowSub: React.Dispatch<React.SetStateAction<boolean>>;
}

const EngagingCreators: React.FC<EngagingCreatorsProps> = ({
  showSub,
  setShowSub,
}) => {
  const creatorsData = [
    {
      name: "Vincent Nate",
      followers: "1M Followers",
      image: cardAvatar,
      platforms: [
        { name: "Instagram", icon: Instagram },
        { name: "Youtube", icon: Youtube }, // Replace with appropriate icons
        // { name: "Twitter", icon: Instagram }, // Replace with appropriate icons
      ],
    },
    {
      name: "Vincent Nate",
      followers: "1M Followers",
      image: cardAvatar1,
      platforms: [
        { name: "Instagram", icon: Instagram },
        { name: "Youtube", icon: Youtube }, // Replace with appropriate icons
        // { name: "Twitter", icon: Instagram }, // Replace with appropriate icons
      ],
    },
    {
      name: "Vincent Nate",
      followers: "1M Followers",
      image: cardAvatar2,
      platforms: [
        { name: "Instagram", icon: Instagram },
        { name: "Youtube", icon: Youtube }, // Replace with appropriate icons
        // { name: "Twitter", icon: Instagram }, // Replace with appropriate icons
      ],
    },
  ];
  return (
    <>
      {!showSub ? (
        <div className=" w-full  mt-[40px]">
          <div className="flex justify-between ">
            <h6 className=" text-[19px] font-semibold  text-[19px]">
              Engaging creators
            </h6>
            <button className="hover:text-link_hover  text-[13px] not-italic font-semibold leading-[130%] cursor-pointer underline underline-offset-2 text-primary">
              View all
            </button>
          </div>
          <div className="space-y-5 space-y-5 mt-5">
            {creatorsData.map((creator, index) => (
              <div className="flex justify-start w-full   items-center">
                <img
                  className="rounded-full object-cover w-[48px] h-[48px]"
                  src={creator.image}
                  alt=""
                />
                <div className="flex flex-col ml-3">
                  <h6 className="text-sm not-italic font-semibold leading-[120%]">
                    {creator.name}
                  </h6>
                  <div className="flex items-center mt-[2px]">
                    <p className="text-[#808080] text-xs not-italic font-medium leading-[130%] mr-4">
                      {creator.followers}
                    </p>
                    {creator.platforms.map((platform, idx) => (
                      <span className="w-5 h-5 ml-1">
                        <img
                          className=" h-[20px] w-[20px] cursor-pointer rounded-full object-cover"
                          src={platform.icon}
                          alt={platform.name}
                        />
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <SubscriptionLock
          containerClass="relative max-w-full mt-[24px] h-[275px] max-w-[300px]  border border-solid border-primary rounded-[16px]"
          imgSrc={dummyeng}
          opacity={1}
          heading="Engaging creators"
        />
      )}
    </>
  );
};

export default EngagingCreators;
