import React, { useState, useEffect } from "react";
import { Tabs, Tag } from "antd";
import ReelsIcon from "../../../assets/svg/reelsIcon.svg";
import StoriesIcon from "../../../assets/svg/storiesIcon.svg";
import CaroselIcon from "../../../assets/svg/caroselsIcon.svg";
import Yshorts from "../../../assets/svg/yShort.svg";
import YIcon from "../../../assets/svg/yIcons.svg";
import "./SelectPlatform.scss";
import DeliverableItem from "./DeliverableItem";
import reelBg from "../../../assets/svg/reelbg.svg";
import storiesBg from "../../../assets/svg/storiesbg.svg";
import carBg from "../../../assets/svg/carbg.svg";

import GetSuggestions from "../GetSuggestions";
import GetSuggestionTab from "../../Common/GetSuggestionTab";
import CuratedSuggestionsContent from "./CuratedSuggestionsContent";
import { useGlobalState } from "../../../context/GlobalStateContext";

const { TabPane } = Tabs;

interface SelectedItem {
  isChecked: any;
  icon: any;
  label: any;
  number: number;
  suggestion: boolean;
}

interface ContentSuggestionItem {
  title: string;
  description: string;
  image: string;
  icon: any;
  label: any;
}
interface SelectPlatformProps {
  setIsSelected: any;
  isSelected: any;
}

const SelectPlatform: React.FC<SelectPlatformProps> = ({
  setIsSelected,
  isSelected,
}) => {
  const contentSuggestionData: ContentSuggestionItem[] = [
    {
      title: "Instagram reels Suggestion",
      description:
        "This is an intricately designed suggestion to promote your product using Instagram Reels. Showcase your creativity and connect with your audience through short video content.",
      image: reelBg,
      icon: CaroselIcon,
      label: "reels",
    },
    {
      title: "Instagram stories suggestion",
      description:
        "Engage your audience with captivating stories on Facebook. Highlight your brand's unique features and connect with your customers in a more personal way.",
      image: storiesBg,
      icon: CaroselIcon,
      label: "stories",
    },
    {
      title: "Carousel post",
      description:
        "Engage your audience with captivating stories on Facebook. Highlight your brand's unique features and connect with your customers in a more personal way.",
      image: carBg,
      icon: CaroselIcon,
      label: "Carosel",
    },
  ];

  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const {
    selectedRowsData,
    setSelectedRowsData,
    selectedNichisData,
    setSelectedNichiData,
  } = useGlobalState();

  const [paid, setPaid] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState("1");

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setIsSelected(selectedRowsData.length > 0);
  }, [selectedRowsData]);

  const addToSelectedItems = (selectedItem: SelectedItem | null) => {
    if (selectedItem === null) {
      setSelectedRowsData((prevItems) =>
        prevItems.slice(0, prevItems.length - 1)
      );
    } else {
      const existingItemIndex = selectedRowsData.findIndex(
        (item) => item.label === selectedItem.label
      );

      if (existingItemIndex !== -1) {
        const updatedItems = selectedRowsData.map((item) =>
          item.label === selectedItem.label ? selectedItem : item
        );
        setSelectedRowsData(updatedItems);
      } else {
        setSelectedRowsData((prevItems) => [...prevItems, selectedItem]);
      }
    }
  };

  const handleTagClose = (tag: string) => {
    setSelectedTags((prevTags) => prevTags.filter((t) => t !== tag));
    setSelectedRowsData((prevItems) =>
      prevItems.filter((item) => item.label !== tag)
    );
  };

  return (
    <>
      <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="w-full md:w-5/6 mx-auto mt-10 px-10">
          {!paid && (
            <GetSuggestions
              title={"Having trouble selecting the right medium?"}
              paid={paid}
              setPaid={setPaid}
              setActiveTab={setActiveTab}
            />
          )}
          <div className="flex justify-start items-stretch h-full w-full border-primary border rounded-[16px]">
            <div className="flex flex-col p-[32px] w-full h-full">
              <div className="flex flex-col">
                <h1 className="text-[19px] font-semibold leading-[120%] tracking-[-1px]">
                  Select deliverables
                </h1>
                {paid && (
                  <div
                    className={`${
                      activeTab === "1" ? "mb-2" : "mb-6"
                    }  min-w-[367px] min-h-11 p-1 mt-2 bg-amber-100 rounded-[50px] border border-yellow-600 justify-start items-start gap-1 inline-flex`}
                  >
                    <GetSuggestionTab
                      label="Explore creators"
                      active={activeTab === "1"}
                      onClick={() => handleTabClick("1")}
                      color="zinc-900"
                      icon={""}
                      bgColor="yellow-400"
                    />
                    <GetSuggestionTab
                      label="Curated suggestions"
                      active={activeTab === "2"}
                      onClick={() => handleTabClick("2")}
                      color="zinc-900"
                      bgColor="yellow-400"
                      icon={
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_1131_5983)">
                            <path
                              d="M8 0L8.45322 2.75156C8.85859 5.21255 10.7874 7.14141 13.2484 7.54678L16 8L13.2484 8.45322C10.7874 8.85859 8.85859 10.7874 8.45322 13.2484L8 16L7.54678 13.2484C7.14141 10.7874 5.21255 8.85859 2.75156 8.45322L0 8L2.75156 7.54678C5.21255 7.14141 7.14141 5.21255 7.54678 2.75156L8 0Z"
                              fill="#1F1F1F"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1131_5983">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      }
                    />
                  </div>
                )}
                {activeTab === "1" ? (
                  <div className="selectTabs">
                    <Tabs defaultActiveKey="Instagram">
                      <TabPane
                        tab={
                          <span style={{ color: "#1F1F1F" }}>Instagram</span>
                        }
                        key="Instagram"
                      >
                        <div className="flex gap-3 flex-wrap">
                          <DeliverableItem
                            icon={ReelsIcon}
                            onAddToSelected={addToSelectedItems}
                            label="Reels"
                          />
                          <DeliverableItem
                            icon={StoriesIcon}
                            label="Stories"
                            onAddToSelected={addToSelectedItems}
                          />
                          <DeliverableItem
                            icon={CaroselIcon}
                            label="Carosel"
                            onAddToSelected={addToSelectedItems}
                          />
                        </div>
                      </TabPane>
                      <TabPane
                        tab={<span style={{ color: "#1F1F1F" }}>Youtube</span>}
                        key="Youtube"
                      >
                        <div className="flex gap-3">
                          <DeliverableItem
                            icon={Yshorts}
                            label="shorts"
                            onAddToSelected={addToSelectedItems}
                          />
                          <DeliverableItem
                            icon={YIcon}
                            label="videos"
                            onAddToSelected={addToSelectedItems}
                          />
                        </div>
                      </TabPane>
                      <TabPane
                        tab={<span style={{ color: "#1F1F1F" }}>LinkedIn</span>}
                        key="Linkedin"
                      >
                        <div className="flex gap-3">
                          <DeliverableItem
                            icon={YIcon}
                            label="videos"
                            onAddToSelected={addToSelectedItems}
                          />
                          <DeliverableItem
                            icon={StoriesIcon}
                            label="Stories"
                            onAddToSelected={addToSelectedItems}
                          />
                          <DeliverableItem
                            icon={CaroselIcon}
                            label="Carosel"
                            onAddToSelected={addToSelectedItems}
                          />
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                ) : (
                  <div className="flex justify-between w-fit space-x-3 sm:flex-wrap lg:flex-nowrap ">
                    {contentSuggestionData.map((contentSuggestion, index) => (
                      <CuratedSuggestionsContent
                        onAddToSelected={addToSelectedItems}
                        contentSuggestionData={contentSuggestion}
                        index={index}
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
            {selectedRowsData.length > 0 && (
              <>
                <div className="  max-w-[252px] w-full bg-[#FAFAFA] p-6 rounded-[0_1rem_1rem_0] ">
                  <p className="text-[#666666] text-[11px] not-italic font-semibold leading-[100%]">
                    {selectedRowsData.length} selected
                  </p>
                  {selectedRowsData.map((item, index) => (
                    <div className="flex">
                      <Tag
                        key={index}
                        closeIcon={
                          <span className="flex ml-1 w-3 h-3 justify-center items-center">
                            <svg
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.9989 1.00195L1.00391 6.99695"
                                stroke="#1F1F1F"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7 7.00125L1 1"
                                stroke="#1F1F1F"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        }
                        className="tag-container mt-2 mr-0 rounded-[30px] flex justify-center items-center border border-[#989898] bg-[#EFEFEF] "
                        icon={
                          <span className=" relative w-[26px] h-[26px] flex bg-white p-[5px] rounded-2xl  mr-2 ">
                            <img
                              className="m-0 "
                              src={item.icon}
                              alt={item.label}
                            />
                            {item.suggestion && (
                              <span className=" absolute flex justify-center items-center bottom-0 right-0 z-5 w-[14px] h-[14px] bg-[#D1A300]  border border-[#B18A00] rounded-[30px] ">
                                <span className="w-2.5 h-2.5 ">
                                  <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    fill="white"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5 0L5.28327 1.71972C5.53662 3.25784 6.74216 4.46338 8.28028 4.71673L10 5L8.28028 5.28327C6.74216 5.53662 5.53662 6.74216 5.28327 8.28028L5 10L4.71673 8.28028C4.46338 6.74216 3.25784 5.53662 1.71972 5.28327L0 5L1.71972 4.71673C3.25784 4.46338 4.46338 3.25784 4.71673 1.71972L5 0Z"
                                      fill="white"
                                    />
                                  </svg>
                                </span>
                              </span>
                            )}
                          </span>
                        }
                        closable
                        onClose={() => handleTagClose(item.label)}
                      >
                        <span className="flex">
                          <p className="  text-[13px] not-italic font-medium leading-[130%]">{` ${item.label} (${item.number})`}</p>
                        </span>
                      </Tag>
                    </div>
                  ))}
                  <button
                    onClick={() => setSelectedRowsData([])}
                    className="text-[13px] not-italic cursor-pointer hover:text-link_hover font-semibold leading-[130%] underline mt-4"
                  >
                    Clear all
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectPlatform;
