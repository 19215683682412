import React, { useState } from "react";
import { Checkbox, InputNumber, Tooltip } from "antd";
import { DownArrow, UpArrow } from "../../../Icons";

interface ContentSuggestionItem {
  icon: any;
  label: any;
  title: string;
  description: string;
  image: string;
}

interface CuratedSuggestionsContentProps {
  contentSuggestionData: ContentSuggestionItem;
  index: number;
  onAddToSelected: (selectedItem: any) => void;
}

const CuratedSuggestionsContent: React.FC<CuratedSuggestionsContentProps> = ({
  contentSuggestionData,
  index,
  onAddToSelected,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [inputValue, setInputValue] = useState(2);

  const onCheckboxChange = (e: any) => {
    const newCheckedValue = e.target.checked;
    setIsChecked(newCheckedValue);

    if (!newCheckedValue) {
      onRemoveFromSelected();
    } else {
      const selectedItem = {
        icon: contentSuggestionData.icon,
        label: contentSuggestionData.label,
        number: inputValue,
        suggestion: true,
      };
      onAddToSelected(selectedItem);
    }
  };
  const onInputNumberChange = (value: number | string | any) => {
    const newValue = Number(value);
    setInputValue(newValue);

    if (isChecked) {
      const selectedItem = {
        icon: contentSuggestionData.icon,
        label: contentSuggestionData.label,
        number: newValue,
        suggestion: true,
      };
      onAddToSelected(selectedItem);
    }
  };

  const onRemoveFromSelected = () => {
    onAddToSelected(null);
  };

  return (
    <div
      key={index}
      className="p-2  flex-col border max-w-[245px] border-solid border-[#EFEFEF] rounded-[12px]  flex justify-between items-center"
    >
      <div className="rounded-lg overflow-hidden relative">
        <span className="bg-[#6C5606] bg-opacity-[0.09] h-[110px] w-[225px]  rounded-lg  flex justify-center items-center">
          <img
            src={contentSuggestionData.image}
            alt="image"
            className="custom-carousel-image w-[71px] h-[90px]"
          />
        </span>
        <div className=" absolute left-1.5 top-1">
          <Checkbox
            className="primary-checkbox"
            checked={isChecked}
            onChange={onCheckboxChange}
          />
        </div>
        <div className="p-[12px] text-left w-full">
          <h4 className="text-base font-semibold leading-[19px] mt-2">
            {contentSuggestionData.title}
          </h4>
          <Tooltip title={`${contentSuggestionData.description}`}>
            <p className="text-[13px] mt-3 not-italic font-medium text-primary line-clamp-2">
              {contentSuggestionData.description}
            </p>
          </Tooltip>
        </div>
      </div>
      {isChecked && (
        <>
          <div className=" mt-4 w-full flex justify-start flex-col pl-[30px] items-start">
            <InputNumber
              className="custom-input-number always-show-handlers px-4 py-2 text-sm not-italic font-normal leading-[140%]"
              min={1}
              max={10}
              value={inputValue}
              onChange={onInputNumberChange}
              controls={{ upIcon: <UpArrow />, downIcon: <DownArrow /> }}
            />
            <button className=" hover:text-link_hover text-[13px] not-italic mt-3 font-semibold leading-[130%] underline">
              Add individual price
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CuratedSuggestionsContent;
