import React from "react";
import SideNav from "../components/Dashboard/SideNav";
import DashboardContents from "../components/Dashboard/DashboardContents";

const Dashboard = () => {
  return (
    <div className="w-full flex text-black">
      <SideNav />
      <DashboardContents />
    </div>
  );
};

export default Dashboard;
