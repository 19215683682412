import React, { useRef } from "react";
import WordCloudChart from "./WordCloudChart";
import { Select } from "antd";
import dropdownIcon from "../../assets/svg/downArrowBlack.svg";
import "./BrandSentiment.scss";
import SubscriptionLock from "./SubscriptionLock";
import brandDummy from "../../assets/svg/brandDummy.svg";

interface BrandSentimentProps {
  showSub: boolean;
  setShowSub: React.Dispatch<React.SetStateAction<boolean>>;
}

const BrandSentiment: React.FC<BrandSentimentProps> = ({
  showSub,
  setShowSub,
}) => {
  const wordCloudData = [
    { text: "Information", value: 10 },
    { text: "Timelines", value: 16 },
    { text: "Dashboard", value: 12 },
    { text: "Icons", value: 14 },
    { text: "Pictures", value: 33 },
    { text: "Visuals", value: 18 },
    { text: "Charts", value: 60 },
    { text: "Knowledge", value: 20 },
    { text: "Analysis", value: 14 },
    { text: "Maps", value: 8 },
    { text: "Creativity", value: 17 },
    { text: "Communication", value: 13 },
    { text: "Presentations", value: 30 },
    { text: "Visualizations", value: 19 },
    { text: "Perception", value: 15 },
    { text: "Infographics", value: 9 },
    { text: "Animated", value: 11 },
    { text: "Data", value: 20 },
    { text: "Topics", value: 15 },
    { text: "Questions", value: 52 },
    { text: "Text", value: 8 },
    { text: "Numbered", value: 80 },
    { text: "People", value: 14 },
    { text: "Categories", value: 18 },
    { text: "Books", value: 10 },
    { text: "Usability", value: 43 },
    { text: "Explore", value: 15 },
    { text: "Social", value: 9 },
    { text: "Freedom", value: 16 },
    { text: "Movies", value: 14 },
    { text: "Cards", value: 8 },
    { text: "Promotions", value: 11 },
    { text: "Students", value: 18 },
  ];

  return (
    <>
      {!showSub ? (
        <section className="bg-white mt-3 relative  rounded-[24px]  px-[24px] py-[25px] border border-solid border-primary ">
          <div className="flex w-full items-center justify-between Brand">
            <h6 className="font-semibold  text-[19px]">Brand sentiment</h6>
            <div className="select-options-container">
              <Select
                defaultValue="Last 3 month"
                suffixIcon={
                  <img className="" src={dropdownIcon} alt="campaignAdd" />
                }
                options={[
                  { value: "Last 2 mo...", label: "Last 3 months" },
                  { value: "Last 4 mo...", label: "Last 4 months" },
                  { value: "Last 5 mo...", label: "Last 5 months" },
                ]}
                // onChange={handleMonthChange}
              />
            </div>
          </div>
          <WordCloudChart data={wordCloudData} />
        </section>
      ) : (
        <SubscriptionLock
          containerClass="relative max-w-full mt-2 h-[400px] max-w-[300px]  border border-solid border-primary rounded-[16px]"
          imgSrc={brandDummy}
          opacity={1}
          heading="Brand sentiment"
        />
      )}
    </>
  );
};

export default BrandSentiment;
