import * as React from 'react';
import { Switch } from 'antd';
import './PrimarySwitch.scss';

interface IPrimarySwitchProps {
	className?: string;
	onChange: () => void;
	defaultChecked?: boolean
}

const PrimarySwitch: React.FunctionComponent<IPrimarySwitchProps> = ({ className, onChange, defaultChecked = false }) => {
	return <Switch className={`primary-switch ${className}`} defaultChecked={defaultChecked} onChange={onChange} />;
};

export default PrimarySwitch;
