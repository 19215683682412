import React, { useState, useRef, useEffect } from "react";

const LanguageDropdown = ({ options }: any) => {
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const dropdownRef: any = useRef(null);
  const toggleDropdown = () => {
    setOpen(!open);
  };

  const handleLanguageSelect = (item: any) => {
    setSelectedLanguage(item);
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative w-full h-12">
      <button
        onClick={toggleDropdown}
        className={`flex w-full items-center justify-between rounded-[8px] bg-white p-2 h-12 focus:border-input_focus border border-solid border-[#989898] }`}
      >
        <span className="text-sm font-medium px-1 leading-4 tracking-normal text-left">
          {selectedLanguage || (options.length > 0 ? options[0] : "")}
        </span>
        <i className="text-xl">
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.8334 1.08325L7.00002 6.91659L1.16669 1.08325"
              stroke="#1F1F1F"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </i>
      </button>

      {open && (
        <ul className="z-2 absolute mt-2 w-full rounded bg-white border border-solid border-[#989898]">
          {options.map((item: any, index: any) => (
            <li
              key={index}
              onClick={() => handleLanguageSelect(item)}
              className="cursor-pointer select-none text-sm py-2.5 px-3 hover:bg-gray-100 font-medium leading-4 tracking-normal text-left"
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageDropdown;
