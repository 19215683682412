import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import EyeIcon from "../../assets/svg/eye.svg";
import GoogleIcon from "../../assets/svg/google.svg";
import EyeCloseIcon from "../../assets/svg/eyeClose.svg";
import { Link, useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (values: any) => {
    console.log("Form values:", values);
    if (validationSchema.isValidSync(values)) {
      navigate("/dashboard");
    }
  };

  return (
    <div className="flex flex-1 flex-col justify-center max-w-md w-[360px]">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors }) => (
          <Form>
            <div className="flex flex-col space-y-2 text-left">
              <h2 className="text-[29px] font-bold leading-[35px]">
                Welcome back,
              </h2>
              <p className="text-sm font-medium leading-[18px] tracking-[0px] text-left text-primary">
                Hey chief, welcome back
              </p>
            </div>
            <div className="flex flex-col max-w-md mt-[24px]">
              <Field
                name="email"
                placeholder="Email"
                type="text"
                className={`text-sm text-primary px-4 py-3 rounded-lg w-full bg-white focus:outline-none border-[#989898] ${
                  errors.email ? "border-input_error" : ""
                } focus:border-input_focus border placeholder-primary`}
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-input_error text-xs font-normal leading-none mt-1"
              />

              <div
                className={`flex items-center mt-5 border ${
                  isFocused ? "border-input_focus" : "border-[#989898]"
                } ${
                  touched.password && errors.password && "border-input_error"
                } border-solid rounded-lg px-4 py-3 w-full bg-white`}
              >
                <Field
                  name="password"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  className="text-sm text-primary w-full bg-white focus:outline-none placeholder-primary"
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
                <div
                  onClick={togglePasswordVisibility}
                  className="ml-2 text-sm leading-5 cursor-pointer h-[20px] w-[20px]"
                >
                  <img
                    src={showPassword ? EyeCloseIcon : EyeIcon}
                    className="h-5 w-5 rounded bg-white text-purple-700 cursor-pointer"
                    alt="Toggle Password Visibility"
                  />
                </div>
              </div>
              <ErrorMessage
                name="password"
                component="div"
                className="text-input_error text-xs font-normal leading-none mt-1"
              />
              <button
                type="submit"
                className="flex hover:bg-black_button_hover hover:text-primary text-sm font-semibold text-white mt-8 w-full items-center justify-center bg-primary text-white px-3 py-2 rounded-lg font-medium h-[49px]"
              >
                Sign in
              </button>

              <div className="flex items-center justify-center text-[#3A3C3E] text-sm my-8">
                <span className="h-px w-full bg-[#EFEFEF]"></span>
                <span className="text-[#3A3C3E] font-normal px-2">or</span>
                <span className="h-px w-full bg-[#EFEFEF]"></span>
              </div>
              <Link to="/dashboard" className="w-full">
                <button className="flex w-full items-center justify-center flex-none px-3 py-2 md:px-4 md:py-3 border border-solid border-[#1F1F1F] rounded-lg font-medium relative h-[49px] hover:bg-black_button_hover hover:text-primary hover:border-none ">
                  <img src={GoogleIcon} alt="Logo" className="w-4 h-4" />
                  <p className="ml-2 text-sm font-semibold text-primary">
                    Sign in with Google
                  </p>
                </button>
              </Link>
              <div className="mt-5 w-full text-center">
                <p className="text-sm text-[#2F3032]">
                  Don't have an account?
                  <Link
                    to="/signup"
                    className="text-sm ml-1 hover:text-link_hover font-semibold text-primary underline  underline-offset-2"
                  >
                    Sign up
                  </Link>
                </p>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
