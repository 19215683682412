import React, { useState } from "react";
import FloatLabel from "./FloatLabel";
import "./FlotingLabelContainer.scss";
import TagsGroup from "../CreateCampaign/CampaignDetails/TagsGroup";
import { useGlobalState } from "../../context/GlobalStateContext";

interface FlotingLabelProps {}

const FlotingLabelContainer: React.FC<FlotingLabelProps> = () => {
  const [firstName, setFirstName] = useState("");
  const [objective, setObjective] = useState("");
  const [budget, setBudget] = useState("");
  const [niche, setNiche] = useState<string[]>([]);
  const [languageFocus, setLanguageFocus] = useState<string[]>([]);
  const [targetCities, setTargetCities] = useState<string[]>([]);
  const [ageGroup, setAgeGroup] = useState<string[]>([]);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const { setSelectedNichiData, selectedNichisData } = useGlobalState();

  const handleNicheChange = (selectedItems: string[]) => {
    setNiche(selectedItems);
    setSelectedNichiData(selectedItems);
  };

  const handleLanguageFocusChange = (selectedItems: string[]) => {
    setLanguageFocus(selectedItems);
  };

  const handleTargetCitiesChange = (selectedItems: string[]) => {
    setTargetCities(selectedItems);
  };

  const handleAgeGroupChange = (selectedItems: string[]) => {
    setAgeGroup(selectedItems);
  };

  return (
    <div className="mt-5 w-full space-y-5 floting">
      <FloatLabel label="Name" value={firstName}>
        <input
          className="w-full outline-none min-h-[46px] pt-2.5 pl-4 pr-2 rounded-lg text-[13px]"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </FloatLabel>
      <FloatLabel label="Objective" value={objective}>
        <textarea
          rows={3}
          value={objective}
          className="w-full resize-none pl-4 h-full rounded-lg focus:outline-none mt-2 px-2 pt-6 text-[13px]"
          onChange={(e) => setObjective(e.target.value)}
        />
      </FloatLabel>

      <FloatLabel label="Budget" value={budget}>
        <div className="relative">
          {isFocused && (
            <span
              className="absolute left-2 z-5 top-1/2 mt-[5px] transform -translate-y-1/2 text-gray-500"
              id="currency-symbol"
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.596 6.542V5.646H3.836C4.85333 5.646 5.60467 5.43133 6.09 5.002C6.58467 4.57267 6.832 4.01267 6.832 3.322C6.832 2.54733 6.57067 1.936 6.048 1.488C5.53467 1.03067 4.774 0.806667 3.766 0.816L0.336 0.83V0.199999H9.352V0.816H5.572L5.866 0.535999C6.55667 0.769333 7.06067 1.138 7.378 1.642C7.69533 2.13667 7.854 2.70133 7.854 3.336C7.854 3.99867 7.69533 4.57267 7.378 5.058C7.06067 5.534 6.60333 5.90267 6.006 6.164C5.40867 6.416 4.69467 6.542 3.864 6.542H1.596ZM6.888 10L4.326 6.122H5.46L8.022 10H6.888ZM0.336 2.776V2.146H9.352V2.776H0.336Z"
                  fill="#1F1F1F"
                />
              </svg>
            </span>
          )}
          <input
            className="w-full outline-none min-h-[46px] pt-2.5 pl-5 pr-2 rounded-lg text-[13px]"
            type="number"
            value={budget}
            onFocus={() => setIsFocused(true)}
            onBlur={() => (budget ? setIsFocused(true) : setIsFocused(false))}
            onChange={(e) => setBudget(e.target.value)}
          />
        </div>
      </FloatLabel>
      <FloatLabel label="Niche" value={selectedNichisData}>
        <TagsGroup
          placeholder=""
          defaultValue={selectedNichisData}
          options={["Fashion/ beauty", "Software products", "Education"]}
          onChange={handleNicheChange}
        />
      </FloatLabel>
      <FloatLabel
        label="Which language would you be focusing on?"
        value={languageFocus}
      >
        <TagsGroup
          placeholder=""
          defaultValue={languageFocus}
          options={["English", "Hindi", "Tamil"]}
          onChange={handleLanguageFocusChange}
        />
      </FloatLabel>
      <FloatLabel label="Pick target cities" value={targetCities}>
        <TagsGroup
          placeholder=""
          defaultValue={targetCities}
          options={[
            "Surat",
            "Banglore",
            "Delhi",
            "Dubai",
            "Kiribati",
            "Kolkatta",
            "Delhi",
            "Dubai",
            "Kiribati",
            "Kolkatta",
            "Tdragon",
          ]}
          onChange={handleTargetCitiesChange}
        />
      </FloatLabel>
      <FloatLabel label="Your preferred age group" value={ageGroup}>
        <TagsGroup
          placeholder=""
          defaultValue={ageGroup}
          options={["13-18 years", "19 - 25 years", "29 - 25 years"]}
          onChange={handleAgeGroupChange}
        />
      </FloatLabel>
    </div>
  );
};

export default FlotingLabelContainer;
