import React, { useRef, useState, useEffect } from "react";
import dropdownIcon from "../../assets/svg/downArrowBlack.svg";
import dummyLeader from "../../assets/svg/dummyleader.svg";
import hulu from "../../assets/png/hulu.png";
import tubi from "../../assets/png/tubi.png";
import le1 from "../../assets/png/le1.png";
import le2 from "../../assets/png/le2.png";
import le3 from "../../assets/png/le3.png";
import le4 from "../../assets/png/le4.png";
import le5 from "../../assets/png/le5.png";
import le6 from "../../assets/png/le6.png";
import "./Leaderboard.scss";
import greenUp from "../../assets/svg/greenUp.svg";
import SubscriptionLock from "./SubscriptionLock";

import "./scrollBar.css";
import { Select } from "antd";

interface LeaderboardItem {
  name: string;
  website: string;
  image: string;
  active: boolean;
}

interface LeaderboardItemProps {
  showSub: boolean;
  setShowSub: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeaderboardItems: LeaderboardItem[] = [
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "tubi",
    website: "tubi.com",
    image: tubi,
    active: false,
  },
  {
    name: "Amazon Prime",
    website: "primevideo.com",
    image: le2,
    active: false,
  },
  {
    name: "Apple",
    website: "tv.apple.com  ",
    image: le3,
    active: false,
  },
  {
    name: "HBO Max",
    website: "max.com",
    image: le4,
    active: false,
  },

  {
    name: "SonyLIV",
    website: "sonyliv.com",
    image: le5,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: le5,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Netflix",
    website: "netflix.com",
    image: tubi,
    active: true,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },

  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
  {
    name: "Hulu",
    website: "hulu.com",
    image: hulu,
    active: false,
  },
];

const Leaderboard: React.FC<LeaderboardItemProps> = ({
  showSub,
  setShowSub,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const activeRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const element = activeRef.current;
      if (element) {
        const rect = element.getBoundingClientRect();
        const offset = rect.top;
        const isActive = offset <= 0;

        if (isActive) {
          setActiveIndex(11);
        } else {
          setActiveIndex(-1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //   const handleCampaignChange = (value: SelectValue) => {
  //     // Handle campaign change logic here
  //   };

  //   const handleMonthChange = (value: SelectValue) => {
  //     // Handle month change logic here
  //   };

  return (
    <>
      {!showSub ? (
        <div className="bg-white text-primary min-w-[300px] max-w-[300px] rounded-[16px] ml-3 border border-solid border-primary leaderboard">
          <div className="px-6 pt-6">
            <h6 className="text-[19px]  not-italic font-semibold leading-[120%] tracking-[-1px] ">
              Leaderboard
            </h6>
            <div className="flex justify-center mt-[20px] select-options-container ">
              <Select
                defaultValue="All campaign"
                style={{ width: 126 }}
                suffixIcon={
                  <img className="" src={dropdownIcon} alt="campaignAdd" />
                }
                options={[
                  { value: "campaign1", label: "All campaign1" },
                  { value: "campaign2", label: "All campaign2" },
                  { value: "campaign3", label: "All campaign3" },
                ]}
                // onChange={handleCampaignChange}
              />
              <Select
                defaultValue="Last 3 months"
                style={{ width: 126, marginLeft: 12 }}
                suffixIcon={
                  <img className="" src={dropdownIcon} alt="campaignAdd" />
                }
                options={[
                  { value: "Last 2 mo...", label: "Last 3 months" },
                  { value: "Last 4 mo...", label: "Last 4 months" },
                  { value: "Last 5 mo...", label: "Last 5 months" },
                ]}
                // onChange={handleMonthChange}
              />
            </div>
          </div>
          <div className="flex mt-5 flex-col overflow-y-scroll flex-1 max-h-[415px]">
            {LeaderboardItems.map((dataItem, index) => (
              <div
                className={`${
                  dataItem.active
                    ? "sticky z-[10] left-0 bottom-0 bg-[#FFF4CC] py-2 "
                    : ""
                } ${
                  activeIndex > 16 ? "sticky top-0 left-0 bg-white z-15" : ""
                }  ${
                  activeIndex > index ? "sticky top-0 bg-white" : ""
                } flex justify-between w-full items-center px-[24px] mb-5`}
                key={index}
                ref={dataItem.active ? activeRef : null}
              >
                <div className="flex items-center">
                  <p className="mr-2 w-5 text-sm font-semibold leading-[17px] tracking-normal text-center">
                    {index + 1}
                  </p>
                  <span
                    className={`flex h-10 w-10 rounded-full items-center justify-center`}
                  >
                    <img
                      className="rounded-full object-cover"
                      src={dataItem.image}
                      alt=""
                    />
                  </span>
                  <div className="flex flex-col ml-3">
                    <h6 className="text-sm not-italic font-semibold leading-[120%]">
                      {dataItem.name}
                    </h6>
                    <div className="flex items-center mt-[2px]">
                      <p className="text-[#808080] text-xs not-italic font-medium leading-[130%] mr-4">
                        {dataItem.website}
                      </p>
                    </div>
                  </div>
                </div>
                {dataItem.active && (
                  <img
                    className="rounded-full object-cover"
                    src={greenUp}
                    alt=""
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <SubscriptionLock
          imgSrc={dummyLeader}
          opacity={1}
          heading="Leaderboard"
          containerClass="relative min-w-[300px] max-w-[300px] ml-3 border border-solid border-primary rounded-[16px]"
        />
      )}
    </>
  );
};

export default Leaderboard;
