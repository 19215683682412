import React, { useState } from "react";
import SearchIcon from "../../assets/svg/searchIcon.svg";
import NotificationIcon from "../../assets/svg/notificationIcon.svg";
import AddIcon from "../../assets/svg/plusAddIcon.svg";
import campaignWhite from "../../assets/svg/campaignWhite.svg";
import { Button, Tooltip, Space } from "antd";
import CoinsIcon from "../../assets/svg/coins.svg";
import cardAvatar from "../../assets/png/user.png";

interface DashboardHeadProps {
  showSub: boolean;
  setShowSub: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardHead: React.FC<DashboardHeadProps> = ({
  showSub,
  setShowSub,
}) => {
  return (
    <div className="flex justify-between w-full h-[40px] items-center">
      <div className="flex  items-center h-[50px] min-w-[310px] border p-[13px] rounded-[100px] border-solid border-primary">
        <div className="flex items-center">
          <img
            className="h-[24px] w-[24px] cursor-pointer"
            src={SearchIcon}
            alt="Search icon"
          />
        </div>
        <input
          type="text"
          id="simple-search"
          className="w-5/6 h-full ml-2 border-none focus:outline-none"
          placeholder="Search key word..."
          required
        />
      </div>
      <div className="pr-[32px] items-center flex  justify-end h-[40px] w-full space-x-5">
        <Button
          type="primary"
          shape="circle"
          onClick={() => setShowSub(!showSub)}
        >
          Locked
        </Button>
        <span className="min-w-[131px] justify-start items-center  flex border pl-1 cursor-pointer pr-3 py-1 rounded-[24px] border border-solid border-primary">
          <img
            className="w-[24px] h-[24px] mr-[6px]"
            src={CoinsIcon}
            alt="Coins icon"
          />
          <p className="text-[14px] cursor-pointer font-medium leading-[17px] tracking-[0px] text-left">
            25,635 coins
          </p>
        </span>
        <img
          className="w-[24px] h-[24px] mr-3 cursor-pointer"
          src={NotificationIcon}
          alt="Notification icon"
        />
        <span className="w-[40px] flex relative items-center justify-center h-[40px] rounded-[20px] bg-primary cursor-pointer">
          <img
            className="w-[16px] h-[16px]"
            src={campaignWhite}
            alt="Add icon"
          />
          <span className="min-w-[14px] flex absolute bottom-0 right-0 border border-solid border-white  items-center justify-center h-[14px] rounded-[20px] bg-[#23762A] cursor-pointer">
            <img className="w-[8px] h-[8px]" src={AddIcon} alt="Add icon" />
          </span>
        </span>
        <div className="flex items-center cursor-pointer ">
          <img
            className="w-10 h-10 rounded-full"
            src={cardAvatar}
            alt="User avatar"
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardHead;
