import React from "react";

interface TabButtonProps {
  label: string;
  active: boolean;
  onClick: () => void;
  color: string;
  bgColor: string;
  icon: any;
}

const GetSuggestionTab: React.FC<TabButtonProps> = ({
  label,
  active,
  onClick,
  color,
  bgColor,
  icon,
}) => {
  return (
    <div
      className={`px-4 py-2 rounded-[21.50px] justify-start items-center flex ${
        active ? `bg-${bgColor}` : `bg-${color}`
      }`}
      onClick={onClick}
    >
      {icon && <div className="w-4 h-4 mr-2">{icon}</div>}
      <button
        className={`text-${color} text-sm not-italic font-semibold leading-[140%]`}
      >
        {label}
      </button>
    </div>
  );
};

export default GetSuggestionTab;
