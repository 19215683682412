import React from "react";
import CreateCampaignHead from "../components/CreateCampaign/CreateCampaignHead";
import CampaignDetails from "../components/CreateCampaign/CampaignDetails/CampaignDetails";
import CreateCampaignFooter from "../components/CreateCampaign/CreateCampaignFooter";
import SelectPlatform from "../components/CreateCampaign/SelectPlatform/SelectPlatform";
import PickCreators from "../components/CreateCampaign/PickCreators/PickCreators";
import PreviewPublish from "../components/PreviewPublish/PreviewPublish";

const CreateCampaign: React.FC = () => {
  const [current, setCurrent] = React.useState(0);
  const [isSelected, setIsSelected] = React.useState(false);

  return (
    <div className="min-h-screen flex flex-col">
      {current < 3 ? (
        <>
          <CreateCampaignHead current={current} setCurrent={setCurrent} />
          <div className="flex-grow overflow-y-auto pb-20 bg-white sm:pb-10 md:pb-20">
            {current === 0 && <CampaignDetails />}
            {current === 1 && (
              <SelectPlatform
                setIsSelected={setIsSelected}
                isSelected={isSelected}
              />
            )}
            {current === 2 && <PickCreators />}
          </div>
          <div className="sticky bottom-0 z-10">
            <CreateCampaignFooter
              isSelected={isSelected}
              current={current}
              setCurrent={setCurrent}
            />
          </div>
        </>
      ) : (
        current === 3 && (
          <PreviewPublish current={current} setCurrent={setCurrent} />
        )
      )}
    </div>
  );
};

export default CreateCampaign;
