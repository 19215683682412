import React, { useState } from "react";
import logo from "../assets/png/logo.png";
import HeroSection from "../components/login/HeroSection";
import SignUpForm from "../components/login/SignUpForm";

const LoginPage = () => {
  return (
    <div className="bg-white">
      <div className="flex min-h-screen">
        <div className="flex flex-row w-full">
          <div className="flex flex-1 flex-col items-center justify-center px-10 relative">
            <div className=" flex justify-start items-start w-full mt-[44px]">
              <img src={logo} alt="Logo" className="w-28 h-8" />
            </div>
            <SignUpForm />
          </div>
          <HeroSection />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
